import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import { useMediaQuery } from "react-responsive";
import { Container, Row, Col } from "react-bootstrap";
import { getSessionToken, thankYouData } from "../../../utils/api";
import { getParameterByName, getLocaleFromURL } from "../../../utils";
import { useLocale } from "context/locale";
import CarImage from "components/CarImage";
import {
  BUSINESS_STEPPER_OPTIONS,
  PRIVATE_STEPPER_OPTIONS,
  RESPONSIVE,
} from "constant";

export default function CustomerSupport() {
  const [data, setData] = useState({});
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const isMediumSize = useMediaQuery({
    minWidth: RESPONSIVE.MIN_WIDTH_MEDIUM,
    maxWidth: RESPONSIVE.MAX_WIDTH_MEDIUM,
  });
  const { locale, setLocale } = useLocale();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const localeURL = getLocaleFromURL(location, "");

  // Fetch data and scroll to top
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSessionToken();
        const { data } = await thankYouData(`${getParameterByName("txNum")}`);
        setLocale((currentLocale) => {
          return localeURL || data.language || currentLocale;
        });
        setData(data);
        // localeContext.setLocale(data["language"]);
        window.scrollTo(0, 0);
        document
          .getElementsByClassName(
            `${isMobile ? "MuiStepLabel-active" : "active"}`
          )[0]
          .scrollIntoView({ behavior: "smooth", inline: "center" });
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [isMobile, localeURL, setLocale]);

  return (
    <div>
      <Header
        stepperOptions={
          data["isPrivate"]
            ? { currentStep: -1, numberOfStep: 6, ...PRIVATE_STEPPER_OPTIONS }
            : { currentStep: -1, numberOfStep: 6, ...BUSINESS_STEPPER_OPTIONS }
        }
      />
      {isMobile && (
        <CarImage data={data} isMobile={isMobile} isMediumSize={isMediumSize} />
      )}
      <Container
        className={`px-4`}
        style={
          isMobile
            ? { backgroundColor: "white", overflow: "auto", marginTop: 15 }
            : { backgroundColor: "white", height: "calc(100vh - 58px)" }
        }
      >
        <div
          className={`content-label ${isMobile ? "s-14 py-0" : "s-16 py-4"}`}
          style={isMobile ? { height: "50px" } : {}}
        >
          {formatMessage({ id: "forward.text.sehr" })}
          {`${
            String(data["title"]).toLowerCase() === "frau"
              ? ` ${formatMessage({ id: "data.icon.mrs" })}`
              : `${locale === "de" ? "r" : ""} ${formatMessage({
                  id: "data.icon.mr",
                })}`
          } ${data["lastName"]}`}
        </div>

        <Row className={`${isMobile ? "mt-2" : "mt-2 mb-4"}`}>
          <Col className={`${isMobile ? "s-14" : "s-16"}`}>
            {data["isSupport"] ? (
              data["status"] === 201 ? (
                <div className="mb-4">
                  <FormattedMessage id="forward.text.notCompletedProcess" />
                </div>
              ) : data["status"] === 202 ? (
                <>
                  <div className={isMobile ? "mb-2" : "mb-4"}>
                    <FormattedMessage id="forward.text.thanksForSubmitting" />
                  </div>
                  <div className={isMobile ? "mb-2" : "mb-4"}>
                    <FormattedMessage id="forward.text.requestOutsideBusinessHours" />
                  </div>
                </>
              ) : (
                <div className="mb-4">
                  <FormattedMessage id="forward.text.notCompletedProcess" />
                </div>
              )
            ) : (
              <div className="mb-4">
                <FormattedMessage id="forward.text.notCompletedProcess" />
              </div>
            )}
            <div className={`${isMobile ? "s-14" : "s-16"} pr-4`}>
              <div className={isMobile ? "mb-2" : "mb-4"}>
                <FormattedMessage
                  id="thankyou.text.bestregards"
                  /* defaultMessage="Freundliche Grüsse" */
                />
              </div>
              <div className={isMobile ? "mb-2" : "mb-4"}>
                <FormattedMessage
                  id="thankyou.text.leaseteg"
                  /* defaultMessage="Ihr LeaseTeq Team" */
                />
              </div>
            </div>
          </Col>
          {!isMobile && (
            <CarImage
              data={data}
              isMobile={isMobile}
              isMediumSize={isMediumSize}
            />
          )}
        </Row>
      </Container>
    </div>
  );
}
