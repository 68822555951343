import React from "react";
import _ from "lodash";
import { BreadcrumbItem } from "./BreadcrumbItem";
import "./Breadcrumb.css";

export default React.memo(function Breadcrumb({ currentStep, items, /*onClick,*/ validPages=[] }) {
  return (
    <>
      <ul id="breadcrumb">
        {_.map(items, ({ title, step }, idx) => (
          <BreadcrumbItem
            key={`BreadcrumbItem-${idx}`}
            label={title}
            index={idx + 1}
            itemsLenght={items.length}
            isBlankItem={false}
            /*onClick={() => {
              if (currentStep === 1 || currentStep === step) {
                return;
              } else if (currentStep < 5) {
                if (step > 5) return;
                else if (step === 5 ) {
                  if(_.isEqual(validPages.sort(), [1,2,3,4])) {
                    onClick(step);
                  } else {
                    return
                  }
                } else {
                  onClick(step);
                }
              }
            }}*/
            isActive={step === currentStep || validPages.includes(step) || _.isEqual(currentStep, -1)}
          />
        ))}
      </ul>
    </>
  );
})
