import { useState, useEffect, useLayoutEffect } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Form, Nav } from "react-bootstrap";
import Select from "react-select";
import { getDebugCalculator } from "utils/api";
import LeasingForm from ".";
import { useLocale } from "../context/locale";
import { useLeasingFormState } from "hooks/useLeasingFormState";
import { useLeasingFormStep } from "hooks/useLeasingFormStep";
import CustomerSupport from "./Common/CustomerSupport";
import ThankYou from "./Common/ThankYou";
import NotFound from "./Common/NotFound";
import LeasingCalculator from "./LeasingFormPublic/index";
import { COUNTRIES, CUSTOM_STYLE, DEFAULT_COUNTRY } from "constant";

// function PrivateRoute({ component: Component, ...rest }) {
//   const isAuthenticated = useAuth();
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isAuthenticated ? <Component {...props} /> : <Redirect to="/404" />
//       }
//     />
//   );
// }

const LIST_MODEL = [
  {
    value: "ModelS",
    label: "ModelS",
  },
  {
    value: "Model3",
    label: "Model3",
  },
  {
    value: "ModelX",
    label: "ModelX",
  },
  {
    value: "ModelY",
    label: "ModelY",
  },
];

const MODEL_OPTIONS = {
  ModelS: "$MTS10,$PBSB,$WS90,$IBE00&",
  Model3: "$MT337,$PPMR,$W40B,$IBB1&",
  ModelX: "$MTX10,$PPSW,$WX00,$IBE00&",
  ModelY: "$MTY07,$PMNG,$WY19B,$INPB0&",
};

const LIST_CUSTOMER_TYPE = [
  {
    value: "PRIVATE",
    label: "PRIVATE",
  },
  {
    value: "BUSINESS",
    label: "BUSINESS",
  },
];
const DEFAULT_MODEL_TYPE = "Model3";

function Home() {
  const { locale, setLocale } = useLocale();
  const [isDebug, setIsDebug] = useState(false);
  const [country, setCountry] = useState(
    COUNTRIES.find((item) => item.value === `${locale}_CH`) || DEFAULT_COUNTRY
  );
  const [modelType, setModelType] = useState(DEFAULT_MODEL_TYPE);
  const [orderId, setOrderId] = useState("RN144555937");
  const [price, setPrice] = useState("4598000");
  const [options, setOptions] = useState(
    MODEL_OPTIONS[DEFAULT_MODEL_TYPE] //"$APBS,$DV2W,$IBB0,$PPSW,$PRM30,$SC04,$MDL3,$W40B,$MT314,$CPF0"
  );
  const [customer, setCustomer] = useState("PRIVATE");
  const [vehicle, setVehicle] = useState("NEW");
  const { clearLeasingFormState } = useLeasingFormState();
  const { clearLeasingFormStep } = useLeasingFormStep();

  const handleChangeModelType = (value) => {
    setModelType(value);
    setOptions(MODEL_OPTIONS[value]);
  };

  useEffect(() => {
    setLocale(country.value.substr(0, 2));
  }, [country.value, setLocale]);

  useLayoutEffect(() => {
    const getDebug = async () => {
      const rs = await getDebugCalculator();
      setIsDebug(rs.data.debugCalculator);
    };
    getDebug();
  }, []);

  return (
    <>
      <style>{`
        .test-form {
          width: 500px;
          margin: auto;
        }
        .test-input {
          margin-top: 8px
        }

        .test-input a {
          color: white;
        }
      `}</style>
      {isDebug && (
        <>
          <form className="test-form">
            <Select
              placeholder={"Select country"}
              isSearchable
              options={COUNTRIES.map((e) =>
                e.value === ""
                  ? { value: e.value, label: e.label, isDisabled: true }
                  : e
              )}
              styles={CUSTOM_STYLE}
              value={country}
              onChange={setCountry}
            />
            <Select
              className="test-input"
              placeholder={"Select model"}
              isSearchable
              options={LIST_MODEL}
              styles={CUSTOM_STYLE}
              value={LIST_MODEL.find((item) => item.value === `${modelType}`)}
              onChange={(e) => handleChangeModelType(e.value)}
            />
            <Form.Control
              className="test-input"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
            <Form.Control
              className="test-input"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <Form.Control
              className="test-input"
              value={options}
              onChange={(e) => setOptions(e.target.value)}
            />
            <Select
              className="test-input"
              placeholder={"Select customer type"}
              isSearchable
              options={LIST_CUSTOMER_TYPE}
              styles={CUSTOM_STYLE}
              value={LIST_CUSTOMER_TYPE.find(
                (item) => item.value === `${customer}`
              )}
              onChange={(e) => setCustomer(e.value)}
            />
            <Form.Control
              className="test-input"
              value={vehicle}
              onChange={(e) => setVehicle(e.target.value)}
            />
            <Nav.Link
              onClick={() => {
                clearLeasingFormState();
                clearLeasingFormStep();
              }}
              to={`/TSL/leasing-form/${modelType}?country=${country.value}&orderID=${orderId}&price=${price}&options=${options}&customer=${customer}&vehicle=${vehicle}`}
              as={Link}
            >
              submit
            </Nav.Link>
          </form>
          <Nav defaultActiveKey="/" className="flex-column">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link
              to="/leasing-form/Model%20S?country=it_CH&orderID=RN144555937&price=4598000&options=$APBS,$DV2W,$IBB0,$PPSW,$PRM30,$SC04,$MDL3,$W40B,$MT314,$CPF0&customer=PRIVATE&vehicle=NEW"
              as={Link}
            >
              Widget
            </Nav.Link>
          </Nav>
        </>
      )}
    </>
  );
}

export default function Routes() {
  return (
    <div>
      <Switch>
        <Route path="/:marke/leasing-form/:carType">
          <LeasingForm />
        </Route>
        <Route path="/" exact>
          <Home />
        </Route>

        <Route path="/:marke/thankyou" component={ThankYou} />
        <Route path="/leasingcalculator" component={LeasingCalculator} />
        <Route path="/:marke/customerSupport" component={CustomerSupport} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}
