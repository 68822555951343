import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";

export default function CheckBoxField({ name, label, onChangeCheckBox }) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Form.Group controlId={`${name}CheckBox`}>
          <b>
            <Form.Check
              onBlur={onBlur}
              onChange={onChangeCheckBox ? onChangeCheckBox : onChange}
              checked={value}
              type="checkbox"
              label={
                <>
                  {label}
                  <sup className="required"> *</sup>
                </>
              }
              custom
              ref={ref}
            />
          </b>
        </Form.Group>
      )}
    />
  );
}
