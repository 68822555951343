import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useIntl } from "react-intl";
import { RESPONSIVE } from "constant";
import { useMediaQuery } from "react-responsive";
import Layout from "components/Layout";
import LockIcon from "components/LockIcon";
import InputRow from "components/InputRow";
import { FormBlock, FormButton, FormInput } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { Form, Container, Row, Col, Button, Modal } from "react-bootstrap";
import CheckBoxField from "components/CheckBoxField";
import { useLocale } from "context/locale";
import { getTerm } from "utils/api";
import { Document, Page, pdfjs } from "react-pdf";
import NumberInput from "components/NumberInput";
import "./Financial.css";

const ContractModal = ({ children, ...props }) => {
  const [pdfLink, setPDFLink] = useState();
  const { locale } = useLocale();

  // Get pdf url
  useEffect(async () => {
    const { data } = await getTerm(locale);
    setPDFLink(data.pdfURL);
  }, [locale]);
  const { formatMessage } = useIntl();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="overflow-auto"
    >
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <a href={pdfLink} download="LeaseteqTC">
          <Button variant="secondary">
            {formatMessage({ id: "confirmation.button.contractDownload" })}
          </Button>
        </a>
        <Button variant="secondary" onClick={props.onHide}>
          {formatMessage({ id: "confirmation.button.contractPopupCancel" })}
        </Button>
        <Button disabled={!props.isReadAll} onClick={props.onConfirm}>
          {formatMessage({ id: "confirmation.button.contractPopupRead" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function Financial({ stepperOptions, onNextStep, onBackStep }) {
  const { formatMessage } = useIntl();
  const [isOpenModal, setOpenModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoadSuccess, setLoadSuccess] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [isNewFoundCompany, setIsNewFoundCompany] = useState(false);
  const { locale } = useLocale();
  const [pdfLink, setPDFLink] = useState();
  const [zoom, setZoom] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const is4K = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_4K });
  const isMedium = useMediaQuery({
    minWidth: RESPONSIVE.MIN_WIDTH_MEDIUM,
    maxWidth: RESPONSIVE.MAX_WIDTH_MEDIUM,
  });

  const {
    watch,
    formState: { errors, touchedFields },
    getValues,
    control,
    setValue,
  } = useFormContext();

  // Validate and go to next page
  const _onNextStep = async () => {
    const isValid = await stepperOptions.triggerForm(4);
    if (isValid) {
      onNextStep();
    }
  };

  // Validate and go to previous page
  const _onBackStep = async () => {
    await stepperOptions.triggerForm(4);
    onBackStep();
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoadSuccess(true);
    setNumPages(numPages);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getTerm(locale);
      setPDFLink(data.pdfURL);
    };
    fetchData();
  }, [locale]);

  useEffect(() => {
    if (+getValues("companyFounded").value === +moment().year()) {
      setValue("pastTurnover2", 0);
      setValue("pastResult2", 0);
      setValue("pastEquity2", 0);
      setIsNewFoundCompany(true);
    }
  }, [getValues, setValue]);

  return (
    <>
      <Layout
        stepperOptions={stepperOptions}
        onNextStep={_onNextStep}
        onBackStep={_onBackStep}
        footer={{ icon: <LockIcon /> }}
      >
        <FormBlock
          title={formatMessage({ id: "financials.title.pastRevenue" })}
        >
          <div
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "17px",
              marginBottom: "1rem",
              marginTop: isMobile ? "0.5rem" : "auto",
            }}
          >
            {formatMessage({ id: "financials.title.subheadline" })}
          </div>
          <InputRow>
            <div className={`d-flex flex-grow-1`}>
              <Col className="pl-0" lg={9} md={9} sm={9} xs={9}>
                <div className={`d-flex flex-grow-1`}>
                  <Col className="pl-0">
                    <Form.Control
                      className="bt-green"
                      disabled
                      value={formatMessage({
                        id: "financials.input.pastYear",
                      })}
                    />
                  </Col>
                  <Col className="pr-0">
                    <Form.Control
                      className="bt-green"
                      disabled
                      value={formatMessage({
                        id: "financials.input.currentYear",
                      })}
                    />
                  </Col>
                </div>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}></Col>
            </div>
          </InputRow>
          <InputRow
            isValid={
              watch("pastTurnover2") &&
              !errors.pastTurnover2 &&
              watch("pastTurnover1") &&
              !errors.pastTurnover1
            }
            isShowError={
              !!errors.pastTurnover2?.message || !!errors.pastTurnover1?.message
            }
            label={formatMessage({
              id: "financials.input.pastRevenues",
            })}
            // tooltip={formatMessage({
            //   id: "financials.input.pastRevenues.tooltip",
            // })}
            isRequired
          >
            <div className={`d-flex flex-grow-1`}>
              <Col className="pl-0" lg={9} md={9} sm={9} xs={9}>
                <div className={`d-flex flex-grow-1`}>
                  <Col className="pl-0">
                    <Controller
                      name="pastTurnover2"
                      control={control}
                      defaultValue={getValues("pastTurnover2")}
                      render={({ field }) => {
                        return (
                          <NumberInput
                            name="pastTurnover2"
                            field={field}
                            readOnly={isNewFoundCompany}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col className="pr-0">
                    <Controller
                      name="pastTurnover1"
                      control={control}
                      defaultValue={getValues("pastTurnover1")}
                      render={({ field }) => {
                        return (
                          <NumberInput name="pastTurnover1" field={field} />
                        );
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col
                className="pr-0 text-secondary d-flex align-items-center"
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                {formatMessage({ id: "common.text.money" })}
              </Col>
            </div>
          </InputRow>
          <InputRow
            isValid={
              watch("pastResult2") &&
              !errors.pastResult2 &&
              watch("pastResult1") &&
              !errors.pastResult1
            }
            isShowError={
              !!errors.pastResult2?.message || !!errors.pastResult1?.message
            }
            label={formatMessage({
              id: "financials.input.pastResults",
            })}
            // tooltip={formatMessage({
            //   id: "financials.input.pastResults.tooltip",
            // })}
            isRequired
          >
            <div className={`d-flex flex-grow-1`}>
              <Col className="pl-0" lg={9} md={9} sm={9} xs={9}>
                <div className={`d-flex flex-grow-1`}>
                  <Col className="pl-0">
                    <Controller
                      name="pastResult2"
                      control={control}
                      defaultValue={getValues("pastResult2")}
                      render={({ field }) => {
                        return (
                          <NumberInput
                            name="pastResult2"
                            field={field}
                            readOnly={isNewFoundCompany}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col className="pr-0">
                    <Controller
                      name="pastResult1"
                      control={control}
                      defaultValue={getValues("pastResult1")}
                      render={({ field }) => {
                        return <NumberInput name="pastResult1" field={field} />;
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col
                className="pr-0 text-secondary d-flex align-items-center"
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                {formatMessage({ id: "common.text.money" })}
              </Col>
            </div>
          </InputRow>
          <InputRow
            isValid={
              watch("pastEquity2") &&
              !errors.pastEquity2 &&
              watch("pastEquity1") &&
              !errors.pastEquity1
            }
            isShowError={
              !!errors.pastEquity2?.message || !!errors.pastEquity1?.message
            }
            label={formatMessage({
              id: "financials.input.equity",
            })}
            // tooltip={formatMessage({
            //   id: "financials.input.equity.tooltip",
            // })}
            isRequired
          >
            <div className={`d-flex flex-grow-1`}>
              <Col className="pl-0" lg={9} md={9} sm={9} xs={9}>
                <div className={`d-flex flex-grow-1`}>
                  <Col className="pl-0">
                    <Controller
                      name="pastEquity2"
                      control={control}
                      defaultValue={getValues("pastEquity2")}
                      render={({ field }) => {
                        return (
                          <NumberInput
                            name="pastEquity2"
                            field={field}
                            readOnly={isNewFoundCompany}
                          />
                        );
                      }}
                    />
                  </Col>
                  <Col className="pr-0">
                    <Controller
                      name="pastEquity1"
                      control={control}
                      defaultValue={getValues("pastEquity1")}
                      render={({ field }) => {
                        return <NumberInput name="pastEquity1" field={field} />;
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <Col
                className="pr-0 text-secondary d-flex align-items-center"
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                {formatMessage({ id: "common.text.money" })}
              </Col>
            </div>
          </InputRow>
        </FormBlock>
        <FormBlock
        // title={formatMessage({ id: "financials.title.actualRevenue" })}
        >
          {/* <Controller
            name="actualRevenue"
            control={control}
            defaultValue={getValues("actualRevenue")}
            render={({ field }) => {
              return (
                <InputRow
                  isValid={watch("actualRevenue") && !errors.actualRevenue}
                  isShowError={!!errors.actualRevenue?.message}
                  label={formatMessage({
                    id: "financials.input.averageRevenue",
                  })}
                  tooltip={formatMessage({
                    id: "financials.input.averageRevenue.tooltip",
                  })}
                  isRequired
                >
                  <div className={`d-flex flex-grow-1`}>
                    <Col className="pl-0" lg={9} md={9} sm={9} xs={9}>
                      <NumberInput name="actualRevenue" field={field} />
                    </Col>
                    <Col
                      className="pr-0 text-secondary d-flex align-items-center"
                      lg={3}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      {formatMessage({ id: "common.text.monthlymoney" })}
                    </Col>
                  </div>
                </InputRow>
              );
            }}
          />
          <Controller
            name="monthlyMean"
            control={control}
            defaultValue={getValues("monthlyMean")}
            render={({ field }) => {
              return (
                <InputRow
                  isValid={watch("monthlyMean") && !errors.monthlyMean}
                  isShowError={!!errors.monthlyMean?.message}
                  label={formatMessage({
                    id: "financials.input.averageResult",
                  })}
                  tooltip={formatMessage({
                    id: "financials.input.averageResult.tooltip",
                  })}
                  isRequired
                >
                  <div className={`d-flex flex-grow-1`}>
                    <Col className="pl-0" lg={9} md={9} sm={9} xs={9}>
                      <NumberInput name="monthlyMean" field={field} />
                    </Col>
                    <Col
                      className="pr-0 text-secondary d-flex align-items-center"
                      lg={3}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      {formatMessage({ id: "common.text.monthlymoney" })}
                    </Col>
                  </div>
                </InputRow>
              );
            }}
          /> */}
          <Controller
            name="existingContracts"
            control={control}
            defaultValue={getValues("existingContracts")}
            render={({ field }) => {
              return (
                <InputRow
                  isValid={
                    watch("existingContracts") && !errors.existingContracts
                  }
                  isShowError={!!errors.existingContracts?.message}
                  label={formatMessage({
                    id: "financials.input.existingContracts",
                  })}
                  isRequired
                >
                  <div className={`d-flex flex-grow-1`}>
                    <Col className="pl-0" lg={9} md={9} sm={9} xs={9}>
                      <NumberInput name="existingContracts" field={field} />
                    </Col>
                    <Col
                      className="pr-0 text-secondary d-flex align-items-center"
                      lg={3}
                      md={3}
                      sm={3}
                      xs={3}
                    >
                      {formatMessage({ id: "common.text.monthlymoney" })}
                    </Col>
                  </div>
                </InputRow>
              );
            }}
          />
          <Controller
            name="replaceExitContractChecked"
            control={control}
            defaultValue={getValues("replaceExitContractChecked")}
            render={({ field }) => {
              return (
                <FormButton
                  isLeft
                  isShowError={
                    !!errors["attachablepart"] ||
                    !!errors["replaceExitContractChecked"]
                  }
                  isRequired
                  isValid={
                    watch("replaceExitContractChecked") === "Ja"
                      ? watch("attachablepart") !== 0 &&
                        touchedFields["attachablepart"] &&
                        watch("attachablepart") !== ""
                        ? true
                        : false
                      : watch("replaceExitContractChecked") === "Nein"
                      ? true
                      : false
                  }
                  label={formatMessage({
                    id: "financials.input.attachablepart",
                  })}
                  buttons={[
                    {
                      text: formatMessage({ id: "financials.button.yes" }),
                      value: "Ja",
                    },
                    {
                      text: formatMessage({ id: "financials.button.no" }),
                      value: "Nein",
                    },
                  ]}
                  onClick={(e, value) => {
                    field.onChange(value);
                    if (value === "Nein") {
                      setValue("attachablepart", "");
                    }
                  }}
                  {...field}
                >
                  {watch("replaceExitContractChecked") === "Ja" && (
                    <Row
                      className={`d-flex align-items-center ml-2 ${
                        isMobile ? "w-55" : "w-80"
                      }`}
                    >
                      <div className="sub-field">
                        <Controller
                          name="attachablepart"
                          control={control}
                          defaultValue={watch("attachablepart")}
                          render={({ field }) => {
                            return (
                              <NumberInput
                                name="attachablepart"
                                field={field}
                              />
                            );
                          }}
                        />
                      </div>
                      <Col md={5} className={`${isMobile ? "p-0" : ""}`}>
                        <p className="text-secondary align-middle p-0 m-0">
                          {formatMessage({ id: "common.text.monthlymoney" })}
                        </p>
                      </Col>
                    </Row>
                  )}
                </FormButton>
              );
            }}
          />
        </FormBlock>
        <style>{`

        .pdf-center {
          display: flex;
          justify-content: center;
        }

        .pdf-resolution {
          max-height: ${
            window.innerHeight *
            (isMobile || isMedium ? 0.5 : is4K ? 0.9 : 0.75)
          }px !important;
        }
    `}</style>
        {isOpenModal && (
          <Document
            loading={""}
            noData={""}
            file={pdfLink}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setOpenModal(false)}
          >
            <ContractModal
              isValid={
                watch("checkBox1") == true &&
                watch("checkBox2") == true &&
                watch("checkBox3") == true
              }
              show={isLoadSuccess}
              onHide={() => {
                setOpenModal(false);
              }}
              onConfirm={() => {
                setValue("checkBox1", true);
                setOpenModal(false);
              }}
              animation={true}
              isReadAll={pageNumber == numPages}
            >
              <Page
                className={`overflow-auto pdf-resolution ${
                  zoom === 1 && "pdf-center"
                }`}
                scale={zoom}
                height={isMobile || isMedium ? window.innerHeight * 0.5 : 1550}
                pageNumber={pageNumber}
              />
              <Row className="pt-3">
                <Col className="d-flex flex-row justify-content-center">
                  {(isMobile || isMedium) && (
                    <div
                      className="d-flex w-100"
                      style={{ justifyContent: "flex-start !important" }}
                    >
                      <Button
                        className="mr-2"
                        disabled={zoom === 1}
                        onClick={() => {
                          setZoom(zoom - 1);
                        }}
                        variant="secondary"
                      >{`-`}</Button>
                      <Button
                        onClick={() => {
                          setZoom(zoom + 1);
                        }}
                        variant="secondary"
                      >{`+`}</Button>
                    </div>
                  )}
                  {numPages > 1 && (
                    <div className="d-flex ml-2">
                      <Button
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                        variant="secondary"
                      >{`<`}</Button>
                      <div className="h3 mx-3">{pageNumber}</div>
                      <Button
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                        variant="secondary"
                      >{`>`}</Button>
                    </div>
                  )}
                </Col>
              </Row>
            </ContractModal>
          </Document>
        )}
        <Controller
          name="remarks"
          control={control}
          defaultValue={getValues("remarks")}
          render={({ field }) => {
            return (
              <FormInput
                isArea
                isLabelEnd
                isValid={touchedFields["remarks"] && !errors.remarks?.message}
                isShowError={!!errors.remarks?.message}
                label={formatMessage({ id: "company.input.remarks" })}
                {...field}
              />
            );
          }}
        />
        <Row className="mt-2">
          <Col>
            <CheckBoxField
              name="checkBox1"
              label={formatMessage({
                id: "confirmation.button.checkBox1",
              })}
              onChangeCheckBox={(e) => {
                if (!getValues("checkBox1")) {
                  setOpenModal(true);
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <CheckBoxField
              name="checkBox2"
              label={formatMessage({
                id: "confirmation.button.checkBox2",
              })}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <CheckBoxField
              name="checkBox3"
              label={`${formatMessage({
                id: `confirmation.button.checkBox3`,
              })} ${formatMessage({
                id: `confirmation.button.checkBox3P2`,
              })}`}
            />
          </Col>
        </Row>
      </Layout>
    </>
  );
}
