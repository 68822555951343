import { useState, useEffect } from "react";
import { Form, ListGroup, Spinner } from "react-bootstrap";
require("dotenv").config();
const CompanyNameAutoComplete = ({ value, setValueInput, trigger }) => {
  const [nameLists, setNameList] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCompanyNameList, setShowCompanyNameList] = useState(false);

  const handleIntrumSearch = async (text, signal) => {
    const url1 = process.env.REACT_APP_COMPANY_NAME_AUTOFILL_URL;
    const url2 = process.env.REACT_APP_API;
    try {
      setIsLoading(true);
      const data = await fetch(`${url2}${url1}${text}`, {
        method: "POST",
        signal,
      });

      if (data.ok) {
        const { response } = await data.json();
        setNameList(response);
        setShowCompanyNameList(true);
        setIsLoading(false);
      } else {
        console.log("error loading company name");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (searchParam.length) {
      handleIntrumSearch(searchParam, signal);
    } else {
      setIsLoading(false);
      setShowCompanyNameList(false);
    }
    return () => {
      controller.abort();
    };
  }, [searchParam]);

  const submitAutoName = (item) => {
    setValueInput("companyName", item);
    setShowCompanyNameList(false);
  };

  return (
    <div id="intrum-company-autocomplete" md={12} sm={12} xs={12}>
      <Form.Control
        type="text"
        className="input-shadow"
        required
        value={value}
        onChange={(e) => {
          e.preventDefault();
          setValueInput("companyName", e.target.value);
          setSearchParam(e.target.value);
        }}
        onFocus={() => {
          setShowCompanyNameList(true);
        }}
      />
      <div className="spinner">
        {isLoading && <Spinner animation="border" variant="success" />}
      </div>

      {nameLists.length > 0 && showCompanyNameList && (
        <ListGroup className="list-group">
          {nameLists.map((i) => (
            <ListGroup.Item key={i} onClick={() => submitAutoName(i)}>
              {i}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default CompanyNameAutoComplete;
