import React from "react";
import Breadcrumb from "../Breadcrumb";
import { useMediaQuery } from "react-responsive";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import { TopHeader } from "components";
import _ from "lodash";
import "./Header.css";

export default React.memo(function Header({ stepperOptions }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <TopHeader />
      <div className="layout-header">
        <div className="menu">
          <div className="container">
            {isMobile ? (
              <>
                <Stepper
                  alternativeLabel
                  nonLinear
                  activeStep={stepperOptions.currentStep - 1}
                >
                  {stepperOptions?.items.map((element) => {
                    return (
                      <Step key={element.title}>
                        <StepButton
                          completed={stepperOptions.validPages?.includes(element.step) || _.isEqual(stepperOptions.currentStep, -1)}
                          onClick={() => {
                            if(stepperOptions.validPages) {
                              if (
                                stepperOptions.currentStep === 1 ||
                                stepperOptions.currentStep === element.step
                              ) {
                                return;
                              } else if (stepperOptions.currentStep < 5) {
                                if (element.step >= 5) return;
                                else if (element.step === 5 ) {
                                  if(_.isEqual(stepperOptions.validPages.sort(), [1,2,3,4])) {
                                    stepperOptions.onClick && stepperOptions.onClick(element.step);
                                  } else {
                                    return
                                  }
                                } else {
                                  stepperOptions.onClick && stepperOptions.onClick(element.step);
                                }
                              }
                            }
                          }}
                        ></StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
                <div
                  className="text-center MuiStepLabel-active"
                  style={{
                    color: "#589958",
                    fontSize: "18pt",
                    paddingBottom: 4,
                  }}
                >
                  {stepperOptions?.items.map((element) => {
                    if (element.step === stepperOptions.currentStep) {
                      return element.title;
                    }
                  })}
                </div>
              </>
            ) : (
              <Breadcrumb {...stepperOptions} />
            )}
          </div>
        </div>
      </div>
    </>
  );
})
