import qs from "query-string";
import _ from "lodash";
import { MODEL_OPTIONS } from "constant";

// Convert to number
export const toNumber = (num, precision = 2) => {
  return Number(Number(num || 0).toFixed(precision));
};

// Get car model
export function getCarModel(carType) {
  return _.get(MODEL_OPTIONS, _.toLower(carType).replace(/\s+/, ""));
}
export function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}
export const pmt = (
  rate_per_period,
  number_of_payments,
  present_value,
  future_value,
  type
) => {
  if (rate_per_period !== 0.0) {
    // Interest rate exists
    var q = Math.pow(1 + rate_per_period, number_of_payments);
    return (
      -(rate_per_period * (future_value + q * present_value)) /
      ((-1 + q) * (1 + rate_per_period * type))
    );
  } else if (number_of_payments !== 0.0) {
    // No interest rate, but number of payments exists
    return -(future_value + present_value) / number_of_payments;
  }

  return 0;
};

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getValueOrDefault = (obj, key, defaultValue = "") => {
  if (obj && typeof obj === "object" && Object.hasOwnProperty.call(obj, key))
    return obj[key];

  return defaultValue;
};

// Convert keys of object to lowercase
export const lowerObject = (obj) =>
  _.transform(obj, function (result, val, key) {
    result[key.toLowerCase()] = val;
  });

// Return locale based on url
export const getLocaleFromURL = (location, defaultLocale = "de") => {
  const queryParams = lowerObject(qs.parse(location.search));
  const lang = queryParams["country"]
    ? queryParams["country"].split("_")[0].toLowerCase()
    : defaultLocale;
  return lang;
};

// Return customer type (private or business) based on url
export const getPrivateURLPath = (location) => {
  const queryParams = lowerObject(qs.parse(location.search));
  queryParams["customer"] = "private";
  return qs.stringify(queryParams);
};

// Return stars that can be displayed on verification and confirmation screen
export const getStar = (responseText1, responseText2, responseText4) => {
  let rs = {};
  if (
    !_.isEmpty(String(responseText1)) &&
    !_.isNull(responseText1) &&
    !_.isEqual(Number(responseText1), 0)
  ) {
    rs["laufzeit"] = "*";
    rs["val1"] = "*";
    if (
      !_.isEmpty(String(responseText4)) &&
      !_.isNull(responseText4) &&
      !_.isEqual(Number(responseText4), 0)
    ) {
      rs["grosse"] = "**";
      rs["val4"] = "**";
      if (
        !_.isEmpty(String(responseText2)) &&
        !_.isNull(responseText2) &&
        !_.isEqual(Number(responseText2), 0)
      ) {
        rs["restwert"] = "***";
        rs["val2"] = "***";
      }
    } else {
      if (
        !_.isEmpty(String(responseText2)) &&
        !_.isNull(responseText2) &&
        !_.isEqual(Number(responseText2), 0)
      ) {
        rs["restwert"] = "**";
        rs["val2"] = "**";
      }
    }
  } else {
    if (
      !_.isEmpty(String(responseText4)) &&
      !_.isNull(responseText4) &&
      !_.isEqual(Number(responseText4), 0)
    ) {
      rs["grosse"] = "*";
      rs["val4"] = "*";
      if (
        !_.isEmpty(String(responseText2)) &&
        !_.isNull(responseText2) &&
        !_.isEqual(Number(responseText2), 0)
      ) {
        rs["restwert"] = "**";
        rs["val2"] = "**";
      }
    } else {
      if (
        !_.isEmpty(String(responseText2)) &&
        !_.isNull(responseText2) &&
        !_.isEqual(Number(responseText2), 0)
      ) {
        rs["restwert"] = "*";
        rs["val2"] = "*";
      }
    }
  }
  return rs;
};

export const isTrue = (value) => ["true", true].includes(value);

export const jumpToView = (view) => {
  return view.current.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};
