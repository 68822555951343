import React from "react";
import { Image } from "react-bootstrap";
import ModelS from "../../../assets/model-s.png";
import ModelX from "../../../assets/model-x.png";
import ModelY from "../../../assets/model-y.png";
import Model3 from "../../../assets/model-3.png";
const carImageLinks = {
  "Model S": ModelS,
  "Model 3": Model3,
  "Model X": ModelX,
  "Model Y": ModelY,
};
const getSrcImage = (model) => {
  return carImageLinks[model];
};

export const ImageComponent = ({ carModel = "Model S" }) => {
  return <Image className={`imageCar`} alt="" src={getSrcImage(carModel)} />;
};
export default ImageComponent;
