import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import { useMediaQuery } from "react-responsive";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { RESPONSIVE } from "constant";
import CarImage from "components/CarImage";
import { getLocaleFromURL } from "utils";

export default function ForwardingInformationScreen({ stepperOptions }) {
  const { getValues } = useFormContext();
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const isMediumSize = useMediaQuery({
    minWidth: RESPONSIVE.MIN_WIDTH_MEDIUM,
    maxWidth: RESPONSIVE.MAX_WIDTH_MEDIUM,
  });
  const { formatMessage } = useIntl();
  const location = useLocation();

  // Scroll view to top and validate page
  useEffect(() => {
    document
      .getElementsByClassName(
        `${isMobile ? "MuiStepLabel-active" : "active"}`
      )[0]
      .scrollIntoView({ behavior: "smooth", inline: "center" });
    async function validPage() {
      await stepperOptions.triggerForm(6);
    }
    validPage();
  }, []);

  return (
    <div>
      <Header stepperOptions={stepperOptions} />
      {isMobile && (
        <CarImage
          data={getValues()}
          isMobile={isMobile}
          isMediumSize={isMediumSize}
        />
      )}
      <Container className={`px-4`}>
        <div
          className={`content-label ${isMobile ? "s-14 py-0" : "s-16 py-4"}`}
          style={isMobile ? { height: "50px" } : {}}
        >
          {formatMessage({ id: "forward.text.sehr" })}
          {`${
            String(getValues("anrede")).toLowerCase() === "frau"
              ? ` ${formatMessage({ id: "data.icon.mrs" })}`
              : `${
                  getLocaleFromURL(location) === "de" ? "r" : ""
                } ${formatMessage({ id: "data.icon.mr" })}`
          } ${getValues("nachname")}`}
        </div>
        <Row className={`${isMobile ? "mt-2" : "mt-2 mb-4"}`}>
          <Col>
            <div className={`${isMobile ? "s-14" : "s-16"} pr-4`}>
              {getValues("isSupport") ? (
                getValues("status") === 201 ? (
                  <div className="mb-4">
                    {formatMessage({ id: "forward.text.notCompletedProcess" })}
                  </div>
                ) : getValues("status") === 202 ? (
                  <>
                    <div className={isMobile ? "mb-2" : "mb-4"}>
                      {formatMessage({ id: "forward.text.thanksForSubmitting" })}
                    </div>
                    <div className={isMobile ? "mb-2" : "mb-4"}>
                      {formatMessage({ id: "forward.text.requestOutsideBusinessHours" })}
                    </div>
                  </>
                ) : getValues("status") === 200 ? (
                  <>
                    <div className={isMobile ? "mb-2" : "mb-4"}>
                      {formatMessage({ id: "thankyou.text.paragraph1" })}
                    </div>
                    <div className={isMobile ? "mb-2" : "mb-4"}>
                      {formatMessage({ id: "thankyou.text.paragraph2" })}
                    </div>
                  </>
                ) : (
                  <div className="mb-4">
                    {formatMessage({ id: "forward.text.notCompletedProcess" })}
                  </div>
                )
              ) : (
                <div className="mb-4">
                  {formatMessage({ id: "forward.text.notCompletedProcess" })}
                </div>
              )}
              <div className={isMobile ? "mb-2" : "mb-4"}>
                {formatMessage({ id: "forward.text.kindRegards" })}
              </div>
              <div className={isMobile ? "mb-2" : "mb-4"}>
                {formatMessage({ id: "forward.text.leaseteqTeam" })}
              </div>
            </div>

            
          </Col>
          {!isMobile && (
            <CarImage
              data={getValues()}
              isMobile={isMobile}
              isMediumSize={isMediumSize}
            />
          )}
        </Row>
      </Container>
    </div>
  );
}
