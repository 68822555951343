import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Clock from "components/clock";
import People from "./People";
import Company from "./Company";
import Financial from "./Financial";
import Application from "./Application";
import Confirmation from "./Confirmation";
import CalculatorBusiness from "./Calculator";
import { useLeasingFormStep } from "hooks/useLeasingFormStep";
import { BUSINESS_STEPPER_OPTIONS, CONCLUDE_BUTTON_STATUS } from "constant";
import Validate from "./Helpers/Validate";
import {
  formatDataBenefitcialOwner,
  formatDataScreen1,
  formatDataScreen2,
  formatDataScreen3,
  formatDataScreen4,
} from "./Helpers/formatter";
import { getContractData, postBusinessData } from "utils/api";
import { useLocale } from "context/locale";
import Loading from "screen/Common/Loading";
import { useIntl } from "react-intl";

export default function LeasingFormBusiness() {
  const { getValues, setValue, trigger, watch } = useFormContext();
  const [loading, setLoading] = useState(false);
  const { formCurrentStep, updateCurrentStep } = useLeasingFormStep();
  const [currentStep, setStep] = useState(formCurrentStep);
  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  // Format and submit data
  const handleSubmit = async () => {
    localStorage.removeItem(CONCLUDE_BUTTON_STATUS);

    const contract = formatDataScreen1(getValues());
    const company = formatDataScreen2(getValues());
    const person = formatDataScreen3(getValues(), locale);
    const financials = formatDataScreen4(getValues());
    const benefitcialOwner = formatDataBenefitcialOwner(getValues());
    setLoading(true);
    
    try {
      const resp = await postBusinessData(
        contract,
        company,
        person,
        financials,
        benefitcialOwner
      );
      if (resp.status === 200) {
        setValue("contractID", resp?.data?.contractID);
        if (resp?.data?.contractID) {
          const contractData = await getContractData(resp?.data?.contractID);
          if (contractData?.data && contractData?.status === 200) {
            setValue("contractData", contractData?.data);
            setStep(5);
            setLoading(false);
          } else {
            setLoading(false);
            setValue("isSupport", true);
            setValue("status", contractData?.status);
            setStep(6);
          }
        } else {
          setStep(5);
          setLoading(false);
        }
      } else {
        setValue("isSupport", true);
        setValue("status", resp.status);
        setLoading(false);
        setStep(6);
      }
    } catch (error) {
      setLoading(false);
      setValue("isSupport", true);
      setStep(6);
    }
  };

  const [validPages, setValidPages] = useState(getValues("validPages"));

  // Page validation
  const triggerForm = async (page) => {
    const isValid = await Validate(page, trigger, watch);
    if (!validPages.includes(page) && isValid) {
      setValidPages((val) => val.concat(page));
    } else if (validPages.includes(page) && !isValid) {
      setValidPages((val) => val.filter((item) => item !== page));
    }
    return isValid;
  };

  // Update current step
  useEffect(() => {
    updateCurrentStep(currentStep);
  }, [currentStep, updateCurrentStep]);

  // Update valid pages
  useEffect(() => {
    setValue("validPages", validPages);
  }, [validPages]);

  switch (currentStep) {
    case 2:
      return (
        <Company
          onBackStep={() => setStep(1)}
          onNextStep={() => setStep(3)}
          stepperOptions={{
            ...BUSINESS_STEPPER_OPTIONS,
            currentStep,
            validPages,
            triggerForm,
            onClick: async (step) => {
              triggerForm(2);
              step === 1 ? setStep(1) : setStep(step);
            },
          }}
        />
      );
    case 3:
      return (
        <People
          onBackStep={() => setStep(2)}
          onNextStep={() => setStep(4)}
          stepperOptions={{
            ...BUSINESS_STEPPER_OPTIONS,
            currentStep,
            validPages,
            triggerForm,
            onClick: (step) => {
              triggerForm(3);
              setStep(step);
            },
          }}
        />
      );
    case 4:
      return (
        <>
          {loading ? (
            <Loading message={formatMessage({ id: "expenses.text.loading" })} />
          ) : (
            <Financial
              onBackStep={() => setStep(3)}
              onNextStep={handleSubmit}
              stepperOptions={{
                ...BUSINESS_STEPPER_OPTIONS,
                validPages,
                currentStep,
                triggerForm,
                onClick: (step) => {
                  triggerForm(4);
                  step < 4 ? setStep(step) : handleSubmit();
                },
              }}
            />
          )}
        </>
      );
    case 5:
      return (
        <Confirmation
          onBackStep={() => setStep(4)}
          onNextStep={() => setStep(6)}
          stepperOptions={{
            ...BUSINESS_STEPPER_OPTIONS,
            validPages,
            triggerForm,
            currentStep,
            onClick: setStep,
          }}
        />
      );
    case 6:
      return (
        <Application
          onBackStep={() => setStep(5)}
          stepperOptions={{
            ...BUSINESS_STEPPER_OPTIONS,
            validPages,
            triggerForm,
            currentStep,
            onClick: setStep,
          }}
        />
      );
    default:
      return (
        <CalculatorBusiness
          onNextStep={() => setStep(2)}
          stepperOptions={{
            ...BUSINESS_STEPPER_OPTIONS,
            validPages,
            currentStep,
            onClick: setStep,
          }}
        />
      );
  }
}
