import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import LogoIcon from "../../assets/icons/LogoIcon.png";
import ButtonComponent from "components/button/buttonComponent";
import Header from "../../components/Header";
import "./layout.css";

export default function Layout({
  title,
  footer,
  children,
  stepperOptions,
  onNextStep,
  onBackStep,
  isNextBtnActive,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  return (
    <div className="layout-root">
      <Header stepperOptions={stepperOptions} />
      <Container
        className={`${isMobile ? "py-2 p-0" : "p-0 py-4"}`}
        style={{ backgroundColor: "white", position: "relative" }}
      >
        <div>
          <p
            className={`layout-title text-left pl-4`}
            style={{ position: "relative" }}
          >
            {!isMobile && title && (
              <>
              <Col
                className="d-flex align-items-center p-0"
              >
                <div className={`d-flex logo-icon-wrapper mr-2`}>
                  <img className="image" src={LogoIcon} alt="LogoIcon" />
                </div>
                <div>
                  {title}
                </div>
              </Col>
                
              </>
            )}
          </p>
        </div>
        <div className={`layout-content ${isMobile ? "py-0 mt-2" : "pt-0"}`}>
          {children}
        </div>
        <Row noGutters className="mt-4 align-items-center">
          <Col
            lg={5}
            md={12}
            xs={12}
            sm={12}
            className={`px-4 ${
              isTablet ? "d-flex justify-content-center mb-2" : ""
            }`}
          >
            {footer?.icon}
            <p className="layout-footer-title">
              {footer?.title || (
                <FormattedMessage
                  id="footer.title.key" /* defaultMessage="Ihre Daten werden verschlüsselt übertragen" */
                />
              )}
            </p>
          </Col>
          <Col
            lg={7}
            md={12}
            xs={12}
            sm={12}
            className={`d-flex justify-content-end px-4 ${
              isMobile
                ? " pt-2"
                : isTablet
                ? "d-flex justify-content-center"
                : ""
            }`}
          >
            <Row>
              {onBackStep && (
                <Col md={{span: 6, order: "first"}} xs={{span: 12, order: "last"}}>
                  <ButtonComponent
                    className={`layout-button ${isMobile ? "w-100" : ""}`}
                    variant="outline-dark"
                    onClick={onBackStep}
                    label={
                      <FormattedMessage
                        id="footer.button.zurück" /* defaultMessage="Zurück" */
                      />
                    }
                  />
                </Col>
              )}
              {onNextStep && (
                <Col md={{span: 6, order: "last"}} xs={{span: 12, order: "first"}}>
                  <ButtonComponent
                    className={`layout-button ${isMobile ? "w-100 mb-2" : ""}`}
                    style={{backgroundColor: "#AFCA0B", borderColor: "#AFCA0B"}}
                    onClick={onNextStep}
                    disabled={isNextBtnActive ? true : false}
                    label={
                      footer?.btn2 || (
                        <FormattedMessage
                          id="footer.button.weiter" /* defaultMessage="Weiter" */
                        />
                      )
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
