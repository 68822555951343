import React from "react";
import { useMediaQuery } from "react-responsive";
import LanguageSwicher from "components/LanguageSwicher";
import { Container, Col } from "react-bootstrap";
import { RESPONSIVE } from "constant";
import "./TopHeader.css";

export default React.memo(function TopHeader() {
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  return (
    <Container className="d-flex p-0">
        <Col className="d-flex align-items-center">
          <LanguageSwicher />
        </Col>
        <Col className="d-flex justify-content-end">
          <div
            className={`logo-wrapper ${
              isMobile ? "logo-size-small" : "logo-size"
            }`}
          >
            <img
              className="logo-image"
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo-header"
            />
          </div>
        </Col>
    </Container>
  );
})
