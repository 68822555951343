import { useCallback, useMemo } from "react";
import {
  LEASING_STATE_KEY,
  LEASING_OPTION_KEY,
  LEASING_STEP_KEY,
} from "constant";
import { getValueOrDefault, lowerObject } from "utils";
import { useLocation } from "react-router";
import queryString from "query-string";

export const useLeasingFormState = () => {
  const { search } = useLocation();

  const options = lowerObject(queryString.parse(search))["options"];

  const prevOptions = localStorage.getItem(LEASING_OPTION_KEY);
  if (prevOptions !== options) {
    localStorage.removeItem(LEASING_STATE_KEY);
    localStorage.removeItem(LEASING_STEP_KEY);
    localStorage.setItem(LEASING_OPTION_KEY, options);
  }

  const setLeasingFormState = useCallback((leasingFormState) => {
    localStorage.setItem(LEASING_STATE_KEY, JSON.stringify(leasingFormState));
  }, []);

  const leasingFormState = useMemo(() => {
    const formState = localStorage.getItem(LEASING_STATE_KEY);
    return formState ? JSON.parse(formState) : {};
  }, []);

  const setDefaultValue = useCallback((setter, key, defaultValue) => {
    const value = getValueOrDefault(leasingFormState, key, defaultValue);
    setter(key, value);
  }, []);

  const clearLeasingFormState = useCallback(() => {
    localStorage.removeItem(LEASING_STATE_KEY);
  }, []);

  return {
    leasingFormState,
    setLeasingFormState,
    setDefaultValue,
    clearLeasingFormState,
  };
};
