import React, { useCallback } from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import "./cta.css";

function ButtonDirection({ direction = "down", onClick }) {
  const _onClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );
  return (
    <button className="btn-direction" onClick={_onClick}>
      {direction === "up" ? (
        <ExpandLessIcon />
      ) : (
        <ExpandLessIcon className="arrow-down" />
      )}
    </button>
  );
}

ButtonDirection.propTypes = {};

export default ButtonDirection;
