import React, { useCallback, useState } from "react";
import "./buttonv2.css";
import { format } from "currency-formatter";
function Button({
  title,
  subTitle,
  onClick,
  status = "",
  size = "",
  type = "",
}) {
  const _onClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );
  //  button
  const button = (
    <button className={`btn-wrap ${type} ${status}`} onClick={_onClick}>
      {title}
    </button>
  );
  // disable primary button
  const buttonDisabled = (
    <button
      className={`btn-wrap ${type} ${status}`}
      onClick={_onClick}
      disabled>
      {title}
    </button>
  );
  // big button
  const bigButton = (
    <button className={`big-button-wrap ${status}`} onClick={_onClick}>
      <div className="big-button-content-wrapper">
        <h6 className="big-button-title">
          {format(title.split(" ")[0], { thousand: "‘", precision: 0 })}{" "}
          {title.split(" ")[1]}
        </h6>
        <div className="big-button-sub-title">{subTitle}</div>
      </div>
    </button>
  );

  // disable button
  const bigButtonDisable = (
    <button className={`big-button-wrap ${status}`} onClick={_onClick} disabled>
      <div className="big-button-content-wrapper">
        <h6 className="big-button-title">
          {format(title.split(" ")[0], { thousand: "‘", precision: 0 })}{" "}
          {title.split(" ")[1]}
        </h6>
        <div className="big-button-sub-title">{subTitle}</div>
      </div>
    </button>
  );
  const primaryButtonFunc = (size) => {
    if (size === "big") {
      return status === "disabled" ? bigButtonDisable : bigButton;
    } else {
      return status === "disabled" ? buttonDisabled : button;
    }
  };

  return <>{primaryButtonFunc(size)}</>;
}

export default Button;
