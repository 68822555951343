import React, { memo, useCallback, useEffect, useState } from "react";
import { Form, ListGroup, Spinner } from "react-bootstrap";
import _ from "lodash";
import { intrumAutocomplete } from "utils/api";

const AutoCompleteAddress = ({ value, setValueInput, fields, trigger }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [optionsAddress, setOptionsAddress] = useState([]);
  const [isShowIntrumAutocomplete, setIsShowIntrumAutocomplete] = useState(
    false
  );

  const handleIntrumAutocomplete = useCallback(
    (search) => {
      setValueInput(`${fields.street}`, search);
      if (search.trim()) {
        setIsLoading(true);
        _.debounce(async () => {
          const { data } = await intrumAutocomplete(search);
          if (data && data.response) {
            setOptionsAddress(data.response);
            setIsLoading(false);
            setIsShowIntrumAutocomplete(true);
          }
        }, 200)();
      }
    },
    [fields, setValueInput]
  );

  const handleAutoFillAddress = useCallback(
    (value) => {
      setValueInput(fields.street, value.street);
      setValueInput(fields.houseNumber, value.houseNumber);
      setValueInput(fields.swissZipCode, value.swissZipCode);
      setValueInput(fields.town, value.town);
      trigger(Object.values(fields));
      setIsShowIntrumAutocomplete(false);
    },
    [fields, setValueInput, trigger]
  );

  useEffect(() => {
    const handle = (e) => {
      const id = e.target.id;
      const parent = e.target.closest("#intrum-autocomplete");
      if ((parent || id === "intrum-autocomplete") && !isLoading) {
        e.preventDefault();
      } else {
        setIsShowIntrumAutocomplete(false);
      }
    };
    window.addEventListener("click", handle, false);
    return () => {
      window.removeEventListener("click", handle, false);
    };
  }, [isLoading]);

  return (
    <div id="intrum-autocomplete" md={8} sm={8} xs={8}>
      <Form.Control
        type="text"
        className="input-shadow"
        required
        value={value}
        onChange={(e) => handleIntrumAutocomplete(e.target.value)}
        onFocus={() => {
          setIsShowIntrumAutocomplete(true);
        }}
      />
      <div className="spinner">
        {isLoading && <Spinner animation="border" variant="success" />}
      </div>
      {optionsAddress.length > 0 && isShowIntrumAutocomplete && (
        <ListGroup className="list-group">
          {optionsAddress.map((item) => (
            <ListGroup.Item
              action
              onClick={() => handleAutoFillAddress(item)}
              key={item.id}
            >
              {item.fullAddress}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default memo(AutoCompleteAddress);
