import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { FormControl, InputGroup } from "react-bootstrap";
import { Calendar as CalendarIcon } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import { useIntl } from "react-intl";

export default function DatepickerComponent({ value, onBlur, onChange, isDoB = false, isMinDateNextDay = false }) {
  const dateTomorrow = new Date();
  dateTomorrow.setDate(dateTomorrow.getDate() + 1)
  const { locale } = useIntl();
  const [localValue, setLocalValue] = useState(!value ? value : isMinDateNextDay ? dateTomorrow : new Date(value));
  const _onChange = useCallback(
    (selectedDate) => {
      setLocalValue(selectedDate);
      if (_.isFunction(onChange)) {
        onChange(selectedDate);
      }
    },
    [onChange]
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div style={{ width: 150 }}>
      <DatePicker
        locale={locale}
        dateFormat="dd.MM.yyyy"
        customInput={
          <InputMask mask="99.99.9999" value={localValue} onChange={onChange}>
            {(inputProps) => (
              <InputGroup>
                <FormControl inputMode="numeric" {...inputProps} />
                <InputGroup.Append>
                  <InputGroup.Text>
                    <CalendarIcon {...inputProps} />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            )}
          </InputMask>
        }
        selected={localValue}
        onChange={(date) => _onChange(date)}
        onBlur={onBlur}
        showMonthDropdown
        showYearDropdown
        scrollableMonthYearDropdown
        dropdownMode="select"
        maxDate={isDoB ? new Date().setFullYear(new Date().getFullYear() - 18) : isMinDateNextDay ? null : new Date()}
        minDate={isDoB ? new Date().setFullYear(new Date().getFullYear() - 89) : isMinDateNextDay ? dateTomorrow : null}
      />
    </div>
  );
}
