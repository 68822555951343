import { RESPONSIVE } from "constant";
import React from "react";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "./IconButton.css";

const IconButton = ({
  isSelected,
  icon,
  onClick,
  label,
  text,
  children,
  svgIcon,
  moreSpace,
  moreWidth
}) => {
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  return (
    <>
      <div className={`d-flex flex-column align-items-center mr-2 `} style={moreSpace ? {flex: 1} : moreWidth ? {width: 150} : {width: "82px"}}>
        <Button
          variant="light"
          className={`${
            isSelected ? "selected" : "bg-white"
          } d-flex justify-content-center align-items-center button ${isMobile ? "w-62" : ""}`}
          onClick={onClick}
          style={moreWidth ? {width: 150} : {}}
        >
          {icon && <span className={icon}></span>}

          {svgIcon && <img src={svgIcon} alt="" />}
          {text && <p className={`p-0 m-0 typo text`}>{text}</p>}
          {children}
        </Button>
        {label && (
          <p className={`text-wrap align-text-center typo ic-label mt-2 ${isMobile ? "s-10" : ""}`}>
            {label}
          </p>
        )}
      </div>
    </>
  );
};

export default IconButton;
