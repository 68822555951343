import React from "react";
import { FormattedMessage } from "react-intl";
import "./style.css";

const NotFound = () => {
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>
              <FormattedMessage id="notfound.text.oops" />
            </h1>
            <h2>
              <FormattedMessage id="notfound.text.404" />
            </h2>
          </div>
          <a href="#">
            <FormattedMessage id="notfound.text.gohome" />
          </a>
        </div>
      </div>
    </>
  );
};

export default NotFound;
