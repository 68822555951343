import React from "react";
import IconButton from "../IconButton";
import InputRow from "../InputRow";

const FormButton = ({
  label,
  buttons = [],
  isRequired,
  isValid,
  value,
  onClick,
  children,
  isShowError = false,
  isIconNotInlineLabel = false,
  isShowStatus = true,
  tooltip,
  moreSpace = false,
  isCenter = false,
  isLeft = false,
  moreWidth = false
}) => {
  return (
    <>
      <InputRow
        label={label}
        isRequired={isRequired}
        isValid={isValid}
        isShowError={isShowError}
        isIconNotInlineLabel={isIconNotInlineLabel}
        isShowStatus={isShowStatus}
        tooltip={tooltip}
      >
        <div className={`d-flex flex-grow-1 ${isCenter ? "justify-content-center" : ""}`} style={isLeft ? {} : {justifyContent:"space-evenly"}}>
          {buttons.map((button, idx) => (
            <IconButton
              moreWidth={moreWidth}
              moreSpace={moreSpace}
              key={`IconButton${button.icon}${idx}`}
              {...button}
              isSelected={button.value === value}
              onClick={(e) => onClick(e, button.value)}
            />
          ))}
          {children}
        </div>
      </InputRow>
    </>
  );
};

export default FormButton;
