import axios from "axios";

export const SASS_DO_ACCESS_TOKEN = "__sass_do_access_token";

const getInstance = (includedAccessToken = true) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*"
  };

  const instance = axios.create({
    headers,
    timeout: 30000,
    baseURL: process.env.REACT_APP_API,
    withCredentials: includedAccessToken ? true : false,
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    function (config) {
      if (includedAccessToken) {
        const accessToken = localStorage.getItem(SASS_DO_ACCESS_TOKEN);
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken = Promise.resolve(Date.now());
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        return instance(originalRequest);
      }
      if (error.response.status === 401) {
        const accessToken = await getSessionToken();
        error.response.config.headers["Authorization"] =
          "Bearer " + accessToken;
        return axios(error.response.config);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export async function getSessionToken() {
  const rs = await getInstance(false).get("api/V1/GETSessionToken");
  const { data } = rs;
  const accessToken = data["session token"];
  if (accessToken) {
    localStorage.setItem(SASS_DO_ACCESS_TOKEN, accessToken);
    return accessToken;
  } else {
    // eslint-disable-next-line no-throw-literal
    throw {
      error: "ACCESS_TOKEN_NOT_FOUND",
      message: "Access token not found.",
    };
  }
}

export function getInitialValues(Model, customer) {
  return getInstance().get("api/V1/GETInitialValues", {
    params: {
      Model,
      customer,
    },
  });
}

export function getOEMs() {
  return getInstance().get("api/V1/GEToems");
}

export function postCustomerData(person, contract, financials, beneficial) {
  return getInstance().post("api/V1/POSTCustomerData", {
    person,
    contract,
    financials,
    beneficial,
  });
}

export function postBusinessData(
  contract,
  company,
  person,
  financials,
  beneficialOwner
) {
  return getInstance().post("api/v1/POSTBusinessData", {
    contract,
    company,
    person,
    financials,
    beneficialOwner,
  });
}

export function getContractData(contractID) {
  return getInstance().get("api/V1/GETContractData", {
    params: {
      contractID,
    },
  });
}

export function postCustomerConsent(contractID) {
  return getInstance().post(
    `api/V1/POSTCustomerConsent?contractID=${contractID}`
  );
}

export function postBusinessConsent(contractID) {
  return getInstance().post(
    `api/v1/POSTBusinessConsent?contractID=${contractID}`
  );
}

export function getCountryByType(value) {
  return getInstance().get(`api/v1/GETcountrybyType?requestType="${value}"`);
}

export function getCounty(value) {
  return getInstance().get(`api/v1/countyList?ISOcountryCode=${value}`);
}

export function getCountry() {
  return getInstance().get(`api/V1/GETCountryData`);
}

export function getLanguageFiles(countryCode) {
  return getInstance().get(`api/v1/languagefiles?countrycode=${countryCode}`);
}

export function getTerm(lang) {
  return getInstance().get(`api/v1/agb?language=${lang}`);
}

export function getDebugCalculator() {
  return getInstance().get("v1/GETdebugCalculator");
}

export function thankYouData(txNum) {
  return getInstance().post("api/v1/thankYou", {
    txNum: txNum,
  });
}

/* export function autoComplete(fullAddress = "", countryCode = "") {
  return getInstance().post(
    `api/v1/googleAutoComplete?fullAddress=${fullAddress}&countryCode=${countryCode}`
  );
}

export function autoCompleteDetail(placeId = "") {
  return getInstance().get(
    `api/v1/googleAutoCompleteDetail?placeID=${placeId}`
  );
} */

export function intrumAutocomplete(fullAddress) {
  return getInstance().post("api/v1/intrumautocomplete", {
    fullAddress,
  });
}


