import React from "react";
import { Col } from "react-bootstrap";
import { getCarModel } from "utils";
import car from "../../assets/model-3.png"

export default function CarImage({ data, isMobile, isMediumSize }) {
  return (
    <Col className={`${isMobile ? "p-0" : ""}`}>
      <div
        className="car-wrapper"
        style={
          isMobile
            ? { height: 180, justifyContent: "center" }
            : isMediumSize
            ? { height: 350, justifyContent: "center" }
            : { height: 230, justifyContent: "center" }
        }
      >
        <img
          className={`imageCar`}
          style={{ width: "unset" }}
          alt=""
          src={car}
        />
      </div>
    </Col>
  );
}
