import moment from "moment";
import { toNumber } from "utils";

// Data formatter

export const formatContractDataScreen1 = (formValues) => ({
  laufleistung: toNumber(formValues["laufleistung"]),
  laufzeit: toNumber(formValues["laufzeit"]),
  kaufpreis: toNumber(formValues["carPrice"]),
  anzahlung: toNumber(formValues["anzahlung"], 0),
  leasingrate: toNumber(formValues["leasingrate"]),
  restwert: toNumber(formValues["restwert"], 2),
  model: formValues["model"],
  orderID: formValues["orderID"],
  carOptions: formValues["carOptions"],
  customerTypePrivate: formValues["customerTypePrivate"],
  vehicleTypeNew: formValues["vehicleTypeNew"],
  nomInterest: Number(formValues["nomInterest"]),
  effInterest: Number(formValues["effInterest"]),
});

export const formatPersonDataScreen2 = (formValues) => ({
  sprache:
    formValues["sprache"].toLowerCase() || formValues["country"].toLowerCase(),
  anrede: formValues["anrede"] || "", //|| "Herr",
  vorname: formValues["vorname"] || "", //|| "Salvatore",
  nachname: formValues["nachname"] || "", //|| "Gambino",
  zivilstand: formValues["zivilstand"] || "", //|| "verheiratet",
  geburtstag: formValues["geburtstag"]
    ? moment(formValues["geburtstag"]).format("YYYY-MM-DD")
    : "", //|| "1966-01-17",
  nationalitaet:
    (formValues["nationalitaet"] || {}).value || formValues["country"] || "CH",
  ausweis: formValues["nationalitaet"] === "CH" ? "" : formValues["ausweis"], //|| "B"
  land: (formValues["land"] || {}).value || formValues["country"] || "CH",
  // added county
  county: (formValues["county"] || {}).value || formValues["county"] || "ZH",
  plz: formValues["plz"] || "", //|| "963000",
  ort: formValues["ort"] || "", //|| "Wattwil",
  strasse: formValues["strasse"] || "", //|| "Wilerstrasse.",
  nummer: formValues["nummer"] || "", //|| "31",
  wohnhaftSeit: toNumber((formValues["wohnhaftSeit"] || {}).value),
  mobil: formValues["mobil"] || "", //|| "+441234",
  privat: formValues["privat"] || "", //|| "+44324324",
  geschaeft: formValues["geschaeft"] || "", //|| "+49123434545",
  weitereTelefonnummer: formValues["weitereTelefonnummer"] || "", //|| "+1234123",
  email: formValues["email"] || "", //|| "stefan.grimm@finbc.de",
});

export const formatBenefitcial = (formValues) => ({
  beneficial: formValues["beneficial"] === "Ja",
  companynamejurP:
    (formValues["naturalPerson"] === "Ja"
      ? ""
      : formValues["companynamejurP"]) || "",
  salutationKInh: formValues["salutationKInh"] || "", //|| "Herr",
  firstnameKInh: formValues["firstnameKInh"] || "", //|| "Salvatore",
  lastnameKInh: formValues["lastnameKInh"] || "", //|| "Gambino",
  birthdayKInh: formValues["birthdayKInh"]
    ? moment(formValues["birthdayKInh"]).format("YYYY-MM-DD")
    : "", //|| "1966-01-17",
  nationalityKInh:
    formValues["beneficial"] === "Ja"
      ? (formValues["nationalityKInh"] || {}).value ||
        formValues["country"] ||
        "CH"
      : "",
  countryKInh:
    formValues["beneficial"] === "Ja"
      ? (formValues["countryKInh"] || {}).value || formValues["country"] || "CH"
      : "",
  postcodeKInh: formValues["postcodeKInh"] || "", //|| "963000",
  placeKInh: formValues["placeKInh"] || "", //|| "Wattwil",
  streetKInh: formValues["streetKInh"] || "", //|| "Wilerstrasse.",
  numberKInh: formValues["numberKInh"] || "", //|| "31",
  postcodejurP:
    (formValues["naturalPerson"] === "Ja" ? "" : formValues["postcodejurP"]) ||
    "", //|| "963000",
  placejurP:
    (formValues["naturalPerson"] === "Ja" ? "" : formValues["placejurP"]) || "", //|| "Wattwil",
  streetjurP:
    (formValues["naturalPerson"] === "Ja" ? "" : formValues["streetjurP"]) ||
    "", //|| "Wilerstrasse.",
  numberjurP:
    (formValues["naturalPerson"] === "Ja" ? "" : formValues["numberjurP"]) ||
    "", //|| "31",
  landKInh:
    formValues["naturalPerson"] === "Ja"
      ? ""
      : formValues["beneficial"] === "Ja"
      ? (formValues["landKInh"] || {}).value || formValues["country"] || "CH"
      : "",
});

export const formatFinancialsDataScreen3 = (formValues) => ({
  landArbeitgeber:
    (formValues["landArbeitgeber"] || {}).value ||
    formValues["country"] ||
    "CH",
  arbeitgeber: formValues["arbeitgeber"] || "", //|| "Finbc",
  PLZArbeitgeber: formValues["PLZArbeitgeber"] || "", //|| "552123",
  ortArbeitgeber: formValues["ortArbeitgeber"] || "", //|| "Entenhausen",
  strasseArbeitgeber: formValues["strasseArbeitgeber"] || "", //|| "Entenhausener Weg 10",
  hausnummerArbeitgeber: formValues["hausnummerArbeitgeber"] || "", //|| "12",
  anstellungsverhaeltnis: formValues["anstellungsverhaeltnis"] || "", //|| "Befristet",
  angestelltSeit: formValues["angestelltSeit"]
    ? moment(formValues["angestelltSeit"]).format("YYYY-MM-DD")
    : "", //|| "2012-12-12",
  angestelltBis: formValues["angestelltBis"]
    ? moment(formValues["angestelltBis"]).format("YYYY-MM-DD")
    : "", //|| "2023-12-12",
  monatlichesNettoEinkommen: toNumber(formValues["monatlichesNettoEinkommen"]), //|| 12000.0,
  dreizehnterMonatslohn: formValues["dreizehnterMonatslohn"] === "Ja", //|| true,
  gratifikation: formValues["gratifikation"] === "Ja", //|| true,
  durchschnittGratifikation: toNumber(formValues["durchschnittGratifikation"]), //|| 10000.0,
  nebenerwerb: toNumber(formValues["nebenerwerb"]), //|| 1000.0,
  vermoegen: toNumber(formValues["vermoegen"]),
});

export const formatFinancialsDataScreen4 = (formValues) => ({
  wohnsituation: formValues["wohnsituation"] || "", //|| "Alleine wohnend",
  wohnMietkosten: toNumber(formValues["wohnMietkosten"]), //|| 1200.0,
  kinderHaushalt: formValues["kinderHaushalt"] === "Ja", //|| true,
  bisSechsJahre: toNumber(formValues["bisSechsJahre"]), //|| 1,
  bisZwoelfJahre: toNumber(formValues["bisZwoelfJahre"]), //|| 1,
  abZwoelfJahre: toNumber(formValues["abZwoelfJahre"]), //|| 1,
  alimente: toNumber(formValues["alimente"]), //|| 200.0,
  andereRegelAuslagen: toNumber(formValues["andereRegelAuslagen"]), //|| 0.0,
  Vertragersatz: toNumber(formValues["Vertragersatz"]), //|| 300.0,
  Betreibungen: formValues["Betreibungen"] === "Ja", //|| true,
  Bemerkungen: formValues["Bemerkungen"] || "", //|| "das wars",
  bestLeasingVert: toNumber(formValues["bestLeasingVert"]), //|| 10000.0,
  Fahrwegkosten: toNumber(formValues["Fahrwegkosten"]), //|| 200.0,
  BegruendungWohnen: formValues["BegruendungWohnen"] || "", //|| "bin unter einer Bruecke",
  livingConfirm: formValues["livingConfirm"] || false,
  livingAgree: formValues["livingAgree"] || false,
});
