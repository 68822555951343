import React from "react";
import Footer from "../../../components/Footer/Footer";
import "../leasingPublic.css";

const FooterView = ({ refProp }) => {
  return (
    <div ref={refProp}>
      <Footer
        telephone="+1234567890"
        email="email@yourEmail.com"
        adresse="xxxxxxxx 11"
        linked="https://www.linkedin.com"
      />
    </div>
  );
};

export default FooterView;
