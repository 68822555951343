import React, { Component } from "react";
import "./input.css";

class InputNumber extends Component {

  decrease = () => {
    if (parseInt(this.props.value) === 0) return;
    this.props.setValue(parseInt(this.props.value) - 1);
  };

  increase = () => {
    this.props.setValue(parseInt(this.props.value) + 1);
  };

  render() {
    return (
      <div className="number-input-wrapper">
        <div className="label col-md-3 pl-0">{this.props.label}</div>
        <div className="number-input justify-content-between align-items-center d-flex">
          <button type="button" onClick={this.decrease} className="minus"></button>
          <input
            className="quantity"
            name="quantity"
            value={this.props.value}
            onChange={() => {}}
            type="number"
          />
          <button type="button" onClick={this.increase} className="plus"></button>
        </div>
      </div>
    );
  }
}

export default InputNumber;
