import React from "react";
import Input from "../input/inputComponent";
import InputRow from "../InputRow";

const FormInput = ({
  label,
  type,
  value,
  isRequired,
  isValid,
  disabled,
  onChange,
  onBlur,
  isArea,
  isLabelEnd,
  errorMessage,
  isShowError,
}) => {
  return (
    <InputRow
      label={label}
      isRequired={isRequired}
      isValid={isValid ?? !!value}
      isLabelEnd={isLabelEnd}
      isShowError={isShowError}
    >
      <Input
        value={value}
        type={type}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        isArea={isArea}
      />
      <span className="text-danger">{errorMessage && errorMessage}</span>
    </InputRow>
  );
};

export default FormInput;
