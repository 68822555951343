import React from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { format } from "currency-formatter";
import { useFormContext } from "react-hook-form";

export default function NumberInput({ name, field, readOnly = false }) {
  const { setValue } = useFormContext();
  const value = field.value === undefined ? "" : field.value;
  return (
    <Form.Control
      inputMode="numeric"
      required
      {...field}
      onChange={(event) => {
        event.preventDefault();
        if (!readOnly) {
          let valueInput = event.target.value.split("‘").join("").trim();
          const isValidInput =
            valueInput === "-" ||
            valueInput === "" ||
            (valueInput[0] === "-" && valueInput.length > 0
              ? !_.isNaN(Number(parseInt(valueInput.substring(1), 10)))
              : !_.isNaN(Number(parseInt(valueInput, 10))));
          if (isValidInput) {
            setValue(name, valueInput);
          }
        }
      }}
      value={
        value !== "-" && value !== "" && value !== undefined
          ? format(value, {
              thousand: "‘",
              precision: 0,
            })
          : value
      }
      readOnly={readOnly}
    />
  );
}
