import React from "react";
import { useMediaQuery } from "react-responsive";
import { Col } from "react-bootstrap";
import LogoIcon from "../../assets/icons/LogoIcon.png";
import "./FormBlock.css";

const FormBlock = ({ title, children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <div className={`${isMobile ? "my-2" : "section"}`}>
        {title && (
          <p className="text-primary text-left title">
            <Col className="d-flex align-items-center p-0">
              <div className={`d-flex logo-icon-wrapper mr-2`}>
                <img className="image" src={LogoIcon} alt="LogoIcon" />
              </div>
              <div>{title}</div>
            </Col>
          </p>
        )}
        <div className={`${isMobile ? "my-0" : "content"}`}>{children}</div>
      </div>
    </>
  );
};

export default FormBlock;
