export const colors = {
  primary: "#79A254",
  info: "#2F80ED",
  success: "#83BC10",
  warning: "#E8E414",
  error: "#FF4828",
  black1: "#000000",
  black2: "#2C2C2C",
  black3: "#363636",
  white: "#FFFFFF",
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#E0E0E0",
  breadcrumbInActive: "#F5F3EF",
  breadcrumbActive: "#AFCA0B",
};
