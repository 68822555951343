import "bootstrap/dist/css/bootstrap.css";
import "core-js/stable";
import "react-datepicker/dist/react-datepicker.css";

import React, { useState, useEffect } from "react";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-US";
import it from "date-fns/locale/it";
import fr from "date-fns/locale/fr";
// import Joi from "joi";
import { registerLocale } from "react-datepicker";
import { IntlProvider } from "react-intl";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";
import "./App.css";

// import { AuthContext } from "./context/auth";
import { LocaleContext } from "./context/locale";

import routers from "./screen/routes";

import {
  getCountryByType,
  getLanguageFiles,
  getSessionToken,
  SASS_DO_ACCESS_TOKEN,
} from "./utils/api";
import Loading from "screen/Common/Loading";
import { CountryContext } from "context/country";
import { LandContext } from "context/land";

const locales = {
  de,
  en,
  it,
  fr,
};

export default function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { search } = useLocation();
  const { country } = queryString.parse(search);
  const [locale, setLocale] = useState(
    _.split(country, "_")[0].toLowerCase() || "de"
  );
  const [messages, setMessages] = useState({});
  const [countryContext, setCountry] = useState(
    JSON.parse(localStorage.getItem("listCountry"))
  );
  const [land, setLand] = useState(
    JSON.parse(localStorage.getItem("listLand"))
  );

  // useEffect(() => {
  //   try {
  //     const formValues = JSON.parse(localStorage.getItem("__formValues"));
  //     const schema = Joi.object().keys({
  //       country: Joi.string().required(),
  //       orderID: Joi.string().required(),
  //       price: Joi.number().required(),
  //       options: Joi.string().required(),
  //       customer: Joi.string().valid("private", "business"),
  //       vehicle: Joi.string().valid("new", "inventory"),
  //     });
  //     const { error } = schema.validate(formValues, {
  //       allowUnknown: true,
  //       abortEarly: true,
  //     });

  //     if (error) {
  //       // setIsAuthenticated(false);
  //     } else {
  //       // setIsAuthenticated(true);
  //       setLocale(formValues.country.substr(0, 2));
  //     }
  //   } catch (err) {
  //     // setIsAuthenticated(false);
  //   }
  // }, []);

  // Get token
  useEffect(() => {
    const getToken = async () => {
      await getSessionToken();
    };
    const accessToken = localStorage.getItem(SASS_DO_ACCESS_TOKEN);
    if (_.isEmpty(accessToken)) {
      getToken();
    }
  }, []);

  // Get language and list country by locale
  useEffect(() => {
    const getToken = async () => {
      await getSessionToken();
    };
    const getLanguageFilesAsync = async () => {
      // let lang;
      // switch (locale) {
      //   case "de":
      //     lang = deLang;
      //     break;
      //   case "en":
      //     lang = enLang;
      //     break;
      //   case "fr":
      //     lang = frLang;
      //     break;
      //   case "it":
      //     lang = itLang;
      //     break;
      // }
      const { data } = await getLanguageFiles(locale);

      if (_.isEmpty(countryContext) && _.isEmpty(land)) {
        const countryRes = await getCountryByType("N");
        const landRes = await getCountryByType("L");

        setCountry(countryRes?.data);
        setLand(landRes?.data);

        localStorage.setItem("listCountry", JSON.stringify(countryRes?.data));
        localStorage.setItem("listLand", JSON.stringify(landRes?.data));
      }

      if (_.isArray(data?.languagefile) && _.size(data?.languagefile) > 0) {
        const _messages = {};
        registerLocale(locale, locales[locale]);
        _.map(data?.languagefile, (item) => {
          _messages[item.fieldname] = item.translation;
        });
        if (!_.isEqual(messages, _messages)) {
          setMessages(_messages);
        }
      }
    };

    const accessToken = localStorage.getItem(SASS_DO_ACCESS_TOKEN);
    if (_.isEmpty(accessToken)) {
      getToken();
      getLanguageFilesAsync();
    } else {
      getLanguageFilesAsync();
    }
  }, [locale]);

  return _.isEmpty(messages) ? (
    <Loading message=" " />
  ) : (
    // <AuthContext.Provider value={isAuthenticated}>
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <CountryContext.Provider value={{ countryContext, setCountry }}>
        <LandContext.Provider value={{ land, setLand }}>
          <IntlProvider locale={locale} key={locale} messages={messages}>
            {routers()}
          </IntlProvider>
        </LandContext.Provider>
      </CountryContext.Provider>
    </LocaleContext.Provider>
    // </AuthContext.Provider>
  );
}
