import React, { useState, useEffect, useCallback, Fragment } from "react";
import "./ToggleSwitch.css";

function ToggleSwitch({ onChange, data }) {
  const [localValue, setLocalValue] = useState("");
  const _onChange = useCallback(
    (event) => {
      setLocalValue(event);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange]
  );
  useEffect(() => {
    setLocalValue(data[0].value);
  }, []);

  return (
    <div className="toggle">
      {data.map((item) => {
        return (
          <Fragment key={item.value}>
            <input
              type="radio"
              name={item.value}
              value={item.value}
              id={item.value}
              checked={localValue === item.value}
              onChange={(e) => _onChange(e.target.value)}
            />
            <label htmlFor={item.value}>{item.label}</label>
          </Fragment>
        );
      })}
    </div>
  );
}

ToggleSwitch.propTypes = {};

export default ToggleSwitch;
