import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Header from "../../../components/Header";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { format } from "currency-formatter";
import { useFormContext } from "react-hook-form";
import { postCustomerConsent } from "utils/api";
import LogoIcon from "../../../assets/icons/LogoIcon.png";
import "./verificationScreen.css";
import { CONCLUDE_BUTTON_STATUS, RESPONSIVE } from "constant";
import ButtonComponent from "components/button/buttonComponent";
import { getCarModel, getStar, isTrue } from "utils";
import _ from "lodash";


const Popup = ({ children, ...props }) => {
  return (
    <Modal
      {...props}
      keyboard={false}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName=""
    >
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Detail = ({ data = { model: "" }, isMobile }) => {
  const { formatMessage } = useIntl();
  const star = getStar(
    data["responseText1"],
    data["responseText2"],
    data["responseText4"]
  );
  return (
    <>
      <Col
        xs={12}
        sm={12}
        md={5}
        lg={5}
        className={`justify-content-center verification-information-box d-flex ${
          isMobile ? "p-4" : ""
        }`}
      >
        <div className="align-self-center w-100">
          <div
            className={`verification-label-model `}
            style={isMobile ? { fontSize: "14pt" } : {}}
          >
            {formatMessage({ id: "review.text.model" })} :{" "}
            {data["model"]}
          </div>
          <div
            className={`${isMobile ? "mb-2" : "mb-4"}`}
            style={{ fontSize: "18pt" }}
          >
            {formatMessage({ id: "review.text.orderID" })} :{" "}
            {data["OrderID"]}
          </div>
          <Row className="text-row">
            <Col>{formatMessage({ id: "review.text.purchasePrice" })}:</Col>
            <Col className="d-flex justify-content-center ">
              {`${format(data["Price"], { thousand: "‘", precision: 0 })} `}
              {formatMessage({ id: "common.text.money" })}
            </Col>
          </Row>

          <Row className="text-row">
            <Col>{formatMessage({ id: "review.text.term" })}:</Col>
            <Col className="d-flex justify-content-center ">
              {`${data["Laufzeit"]} `}
              {formatMessage({ id: "review.text.months" })}{" "}
              {star["laufzeit"] && star["laufzeit"]}
            </Col>
          </Row>

          <Row className="text-row">
            <Col>
              {formatMessage({ id: "review.text.mileage" })}:
            </Col>
            <Col className="d-flex justify-content-center ">
              {`${format(data["Laufleistung"], {
                thousand: ".",
                precision: 0,
              })} `}
              {formatMessage({ id: "review.text.mileage.km" })}
            </Col>
          </Row>

          <Row className="text-row">
            <Col>{formatMessage({ id: "review.text.downPayment" })}:</Col>
            <Col className="d-flex justify-content-center align-items-center ">
              {`${format(data["Anzahlung"], { thousand: "‘", precision: 0 })} `}
              {formatMessage({ id: "common.text.money" })}{" "}
              {star["grosse"] && star["grosse"]}
            </Col>
          </Row>

          <Row className="text-row">
            <Col>{formatMessage({ id: "review.text.residualValue" })}:</Col>
            <Col className="d-flex justify-content-center ">
              {`${format(data["Restwert"], { thousand: "‘", precision: 0 })} `}
              {formatMessage({ id: "common.text.money" })}{" "}
              {star["restwert"] && star["restwert"]}
            </Col>
          </Row>

          <Row className="text-row mt-4">
            <Col>
              <div>
                {formatMessage({ id: "review.text.installment" })}
                <br />
                {formatMessage({ id: "common.text.inchf" })}
              </div>
            </Col>
            <Col>
              <div
                className="d-flex font-weight-bold custom-color-input justify-content-center"
                style={{
                  fontSize: "1.5rem",
                  lineHeight: 1.5,
                  padding: ".5rem 1rem",
                }}
              >
                {`${format(data["Leasingrate"], {
                  thousand: "‘",
                  precision: 2,
                })} `}
                {formatMessage({ id: "common.text.money" })}
              </div>
            </Col>
          </Row>

          <Row className="text-row mt-4">
            <Col>
              {`${formatMessage({
                id: "review.text.nomZins",
              })} ${format(data["nomInterest"], {
                decimal: ".",
              })}% ${formatMessage({ id: "common.text.pa" })} (${formatMessage({
                id: "review.text.effZins",
              })} ${format(data["effInterest"], {
                decimal: ".",
                precision: 4,
              })}% ${formatMessage({
                id: "common.text.pa",
              })})`}
            </Col>
          </Row>

          <Row className="text-row mt-4">
            {star["val1"] && (
              <Col md={12}>
                {star["val1"]}
                {formatMessage(
                  { id: "review.text.responseText1" },
                  { response: Number(data["responseText1"]) + data["Laufzeit"] }
                )}
              </Col>
            )}
            {star["val4"] && (
              <Col md={12}>
                {star["val4"]}
                {formatMessage(
                  { id: "review.text.responseText4" },
                  {
                    response: Number(data["responseText4"]) + data["Anzahlung"],
                  }
                )}
              </Col>
            )}
            {star["val2"] && (
              <Col md={12}>
                {star["val2"]}
                {formatMessage(
                  { id: "review.text.responseText2" },
                  { response: Number(data["responseText2"]) + data["Restwert"] }
                )}
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </>
  );
};

const ImageComponent = ({ formValues, isMobile, isMediumSize }) => {
  return (
    <Col className={`${isMobile ? "p-0 mb-2" : ""}`}>
      <div
        className="car-wrapper"
        style={
          isMobile || isMediumSize
            ? { height: 180, justifyContent: "center" }
            : { height: 230, justifyContent: "center" }
        }
      >
        <img
          className={`imageCar`}
          style={{ width: "unset" }}
          alt=""
          src={`https://static-assets.tesla.com/configurator/compositor?&options=${
            formValues["carOptions"] ||
            "$APBS,$DV2W,$IBB0,$PPSW,$PRM30,$SC04,$MDL3,$W40B,$MT314,$CPF0"
          }&view=STUD_3QTR&model=${getCarModel(
            formValues["model"] || "Model S"
          )}&size=1441&bkba_opt=0`}
        />
      </div>
    </Col>
  );
};

export default function VerificationScreen({ stepperOptions, onNextStep }) {
  const { getValues, setValue } = useFormContext();
  const { formatMessage } = useIntl();
  const formValues = getValues();
  const data = getValues("contractData");
  const contractID = getValues("contractID");
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const isMediumSize = useMediaQuery({
    minWidth: RESPONSIVE.MIN_WIDTH_MEDIUM,
    maxWidth: RESPONSIVE.MAX_WIDTH_MEDIUM,
  });
  const parentRef = useRef();
  const [isShowKYC, setShowKYC] = useState(false);
  const [KYCLink, setKYCLink] = useState("");
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);

  // Check browser is safari or not
  let isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

    // Scroll view to top and validate page
  useEffect(() => {
    document
      .getElementsByClassName(
        `${isMobile ? "MuiStepLabel-active" : "active"}`
      )[0]
      .scrollIntoView({ behavior: "smooth", inline: "center" });
    window.scrollTo(0, 0);
    async function validPage() {
      await stepperOptions.triggerForm(5);
    }
    validPage();
  }, []);

  useEffect(() => {
    setDisableConfirmButton(
      isTrue(localStorage.getItem(CONCLUDE_BUTTON_STATUS))
    );
  }, []);

  const _onSubmit = useCallback(async () => {
    if (contractID) {
      try {
        setDisableConfirmButton(true);
        localStorage.setItem(CONCLUDE_BUTTON_STATUS, true);
        const { data, status } = await postCustomerConsent(contractID);
        if (status === 200) {
          // if(isSafari) {
          let win = window.open("", "_blank", "width=1200,height=1200,left=50,top=50,location=no,menubar=no,resizable=yes,scrollbars=yes,status=no,titlebar=no,toolbar=no");
          win.document.write(
            `<iframe allowusermedia src="${data["IDnowiFrameURL"]}leaseteqesign/identifications/${data["identID"]}" style="width: 100vw; height: 1200px"  allow="camera *;microphone *" allowfullscreen=""></iframe>`
          );
          // } else {
          // setShowKYC(true);
          // setKYCLink(
          //   `${data["IDnowiFrameURL"]}leaseteqesign/identifications/${data["identID"]}`
          // );
          // }
        } else {
          setValue("isSupport", true);
          setValue("status", status);
          onNextStep();
        }
      } catch (error) {
        setDisableConfirmButton(false);
        setValue("isSupport", true);
        onNextStep();
      }
    }
  }, [onNextStep, contractID]);

  return (
    <div>
      <Popup
        size="lg"
        show={isShowKYC}
        onHide={() => {
          setShowKYC(false);
        }}
        backdrop
        animation
      >
        <iframe
          allowusermedia
          frameborder="0"
          width="100%"
          height="600px"
          src={KYCLink}
          allow="camera *;microphone *"
          allowfullscreen=""
        ></iframe>
      </Popup>
      <Header stepperOptions={stepperOptions} />
      <Container
        className={isMobile ? "pb-2" : "pt-4"}
        style={
          isMobile
            ? {
                backgroundColor: "white",
                overflow: "auto",
                position: "relative",
              }
            : { backgroundColor: "white" }
        }
      >
        <Row
          className={`verification-title px-4`}
          style={
            isMobile
              ? { position: "relative", fontSize: "18pt" }
              : { position: "relative" }
          }
        >
          {!isMobile && (
            <>
              <p className="text-left title">
                <Col className="d-flex align-items-center p-0">
                  <div className={`d-flex logo-icon-wrapper mr-2`}>
                    <img className="image" src={LogoIcon} alt="LogoIcon" />
                  </div>
                  <div>
                    {formatMessage({ id: "review.title.pageHeader" })}
                  </div>
                </Col>
              </p>
            </>
          )}
        </Row>
        <Row className={`${isMobile ? "" : "mt-4 p-0 px-4"}`}>
          <Col
            xs={12}
            sm={12}
            md={7}
            lg={7}
            className={`${isMobile ? "p-0" : "pr-65 pl-0"}`}
            ref={parentRef}
          >
            <ImageComponent
              formValues={formValues}
              isMobile={isMobile}
              isMediumSize={isMediumSize}
            />
            <div className={`${isMobile ? "px-4" : ""}`}>
              {isMobile && <Detail data={data} />}
              
              <div
                className={isMobile ? "mt-2" : "mt-4"}
                style={{ fontSize: "14pt" }}
              >
                {formatMessage({ id: "review.text.info" })}
              </div>
              <div className="d-flex my-4 justify-content-center">
                <ButtonComponent
                  style={{ fontSize: "x-large" }}
                  className="layout-button custom-color-input"
                  onClick={_onSubmit}
                  disabled={disableConfirmButton}
                  label={formatMessage({
                    id: "confirmation.button.jetztabschließen",
                  })}
                />
              </div>
            </div>
          </Col>
          {!isMobile && <Detail data={data} />}
        </Row>
      </Container>
    </div>
  );
}
