import React from "react";
import { useMediaQuery } from "react-responsive";
import lockIcon from "../../assets/icons/lock.png";
import "./LockIcon.css"

const LockIcon = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return (<div className={`img-wrapper ${isMobile && "mr-0"}`}>
        <img className="image" src={lockIcon} alt="KeyLock" />
    </div>)
}

export default LockIcon;