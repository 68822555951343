import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import "./input.css";

export default function InputComponent({
  placeholder,
  disabled,
  value,
  onChange,
  onBlur,
  onFocus,
  isArea,
  className,
  size,
  isButton,
}) {
  const [localValue, setLocalValue] = useState(value);
  const _onChange = useCallback(
    (event) => {
      setLocalValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange]
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Form.Control
      size={`${size}`}
      placeholder={placeholder}
      disabled={disabled}
      value={localValue}
      onChange={(event) => _onChange(event)}
      onBlur={onBlur}
      onFocus={onFocus}
      as={isArea ? "textarea" : isButton ? "button" : "input"}
      className={isArea ? "textarea" : className ? className : "input-shadow"}
    >
      {isButton ? localValue : null}
      </Form.Control>
  );
}

InputComponent.defaultProps = {
  disabled: false,
  isArea: false,
  isButton: false
};
InputComponent.propTypes = {
  isButton: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};
