import React from "react";
import Select from "react-select";
import "./DropdownSelect.css";
import IconChecked from "../../assets/icon-checked.svg";
import ChevronDown from "../../assets/icon-chervron-down.svg";
const customStyles = {
  control: (base, state) => {
    // none of react-select's styles are passed to <Control />
    return {
      ...base,
      width: "100%",
      height: 52,
      minHeight: "3.5em",
      borderRadius: "8px",
      border:
        state.hasValue || state.menuIsOpen === true ? "1px solid #79A254" : "",
      outline: "#79A254",
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "transparent"
        : isFocused
        ? "transparent"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "#79A254"
        : isFocused
        ? "#79A254"
        : "#3e3e3e",
      ":active": {
        ...styles[":active"],
        backgroundColor: "transparent",
      },
      ":isFocused": {
        ...styles[":active"],
        backgroundColor: "transparent",
      },
    };
  },
};
function DropdownSelect({
  title,
  placeholder,
  options,
  value,
  customStyle,
  onChange,
}) {
  return (
    <>
      <div className="dropdown-select-wrap">
        <h6 className="title-v2">{title}</h6>
        <Select
          className="dropdown-select"
          placeholder=" "
          isSearchable
          classNamePrefix="ltq-select"
          options={options}
          styles={customStyles}
          value={options.find((option) => option.value === value) || ""}
          onChange={(event) => onChange(event)}
          isDisabled={options.length === 0}
        />
        <label
          className={`label-dropdown-select ${value ? "transform-label" : ""}`}>
          {placeholder}
        </label>
        <div className="checkmark">
          {value ? (
            <img src={IconChecked} alt="checked" className="icon-checked" />
          ) : (
            <img src={ChevronDown} alt="chevron" className="icon-chevron" />
          )}
        </div>
      </div>
    </>
  );
}

export default DropdownSelect;
