import React from "react";
import InputBox from "./InputBox";
import { RESPONSIVE } from "constant";
import { Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

export default function RightBoxItem({
  title,
  name,
  value = undefined,
  isNotInlineTitle = true,
}) {
  const isLargeSize = useMediaQuery({ minWidth: RESPONSIVE.MIN_WIDTH_LARGE });
  return (
    <Row className={`d-flex ${isLargeSize ? "w-100 mb-4" : "mb-2"}`}>
      <Col
        lg={7}
        md={7}
        sm={6}
        xs={6}
        className={`d-flex align-self-center font-weight-bold ${
          isLargeSize ? "" : "p-0"
        }`}
        style={{ fontSize: "14pt" }}
      >
        {isLargeSize ? (
          <>
            <Col lg={3} md={3}></Col>
            <Col
              className={
                isLargeSize
                  ? isNotInlineTitle
                    ? "d-flex justify-content-end"
                    : ""
                  : ""
              }
            >
              {title}
            </Col>
          </>
        ) : (
          title
        )}
      </Col>
      <Col
        lg={4}
        md={4}
        sm={6}
        xs={6}
        className={`d-flex align-self-center ${isLargeSize ? "pr-0 pl-4" : "p-0"}`}
      >
        <InputBox name={name} value={value} />
      </Col>
    </Row>
  );
}
