import { useCallback, useMemo } from "react";
import { LEASING_STEP_KEY } from "constant";

export const useLeasingFormStep = () => {
  const formCurrentStep = useMemo(() => {
    const currentStep = localStorage.getItem(LEASING_STEP_KEY);
    return Number(currentStep) || 1;
  }, []);

  const updateCurrentStep = useCallback((step) => {
    localStorage.setItem(LEASING_STEP_KEY, step);
  }, []);

  const clearLeasingFormStep = useCallback(() => {
    localStorage.removeItem(LEASING_STEP_KEY);
  }, []);

  return {
    formCurrentStep,
    updateCurrentStep,
    clearLeasingFormStep,
  };
};
