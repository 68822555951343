const LINKS = {
  ABOUT_US: {
    de: "https://leaseteq.ch/uber-uns/",
    en: "https://leaseteq.ch/en/about-us/",
    it: "https://leaseteq.ch/it/chi-siamo/",
    fr: "https://leaseteq.ch/fr/sur-nous/",
  },
  IMPRINT: {
    de: "https://leaseteq.ch/imprint/",
    en: "https://leaseteq.ch/en/imprint/",
    it: "https://leaseteq.ch/it/responsabile-del-contenuto/",
    fr: "https://leaseteq.ch/fr/responsable-du-contenu/",
  },
  PRIVACY_POLICY: {
    de: "https://leaseteq.ch/data-privacy/",
    en: "https://leaseteq.ch/en/data-privacy/",
    it: "https://leaseteq.ch/it/protezione-dei-dati/",
    fr: "https://leaseteq.ch/fr/protection-des-donnees-personnelles/",
  },
  HOME_PAGE: {
    de: "https://leaseteq.ch/",
    en: "https://leaseteq.ch/en/",
    it: "https://leaseteq.ch/it/",
    fr: "https://leaseteq.ch/fr/",
  },
  TESTLA_CONFIGURATOR: {
    de: "https://www.tesla.com/de_ch",
    en: "https://www.tesla.com/de_ch",
    it: "https://www.tesla.com/it_ch ",
    fr: "https://www.tesla.com/fr_ch",
  },
};

export const getLink = (locale, link) => {
  return LINKS[link][locale];
};
