import ButtonDirection from "components/CTA/ButtonDirection";
import DropdownSelect from "components/DropdownSelectorV2/DropdownSelect";
import ToggleSwitch from "components/ToggleSwitchV2/ToggleSwitch";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";

const GeneralInformation = ({
  brands,
  selectedBrand,
  models,
  selectedModel,
  onBrandChange,
  onModelChange,
  onCustomerTypeChange,
  goToPaymentView,
}) => {
  const { formatMessage } = useIntl();
  const customerTypes = [
    {
      label: formatMessage({ id: "publicleasing.button.privateleasing" }),
      value: "Private",
    },
    {
      label: formatMessage({ id: "publicleasing.button.businessleasing" }),
      value: "Business",
    },
  ];
  const handleBrandChange = (e) => {
    onBrandChange(e.value);
  };

  return (
    <div
      className="block-content"
      style={{
        height: selectedModel ? "auto" : "80vh",
        paddingTop: selectedModel ? "30vh" : "0",
      }}>
      <Row className="big-title-wrapper">
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
          <p className="big-title">
            {formatMessage({ id: "publicleasing.title.welcome" })}
          </p>
        </Col>
      </Row>
      <Row className="customer-type-wrapper">
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
          <ToggleSwitch onChange={onCustomerTypeChange} data={customerTypes} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="mb-sm-4">
          <DropdownSelect
            placeholder={formatMessage({
              id: "publicleasing.placeholder.choosebrand",
            })}
            options={brands}
            value={selectedBrand}
            onChange={handleBrandChange}
          />
        </Col>
        <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
          <DropdownSelect
            placeholder={formatMessage({
              id: "publicleasing.placeholder.choosemodel",
            })}
            options={selectedBrand ? models : []}
            value={selectedModel}
            onChange={(e) => onModelChange(e.value)}
          />
        </Col>
      </Row>
      {selectedBrand && selectedModel && (
        <Row className="button-direction-wrapper">
          <ButtonDirection direction="down" onClick={() => goToPaymentView()} />
        </Row>
      )}
    </div>
  );
};

export default GeneralInformation;
