import React from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { colors } from "themes/colors";

const Layout = ({ className, label, disabled, ...rest }) => {
  return (
    <>
      <style>{`
        .p-button {
          height: 55px;
          border-radius: 8px;
        }
        .primary {
          background-color: ${colors.primary};
          border-color: ${colors.primary};
          margin-left: 16px;
        }
        .primary:hover {
          background-color: ${colors.primary};
          border-color: ${colors.primary};
        }
      `}</style>
      <Button type="submit" className={classNames("p-button", className)} disabled={disabled} {...rest}>{label}</Button>
    </>
  );
};

export default Layout;
