import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputComponent from "components/input/inputComponent";

export default function InputBox({ name, value = undefined }) {
  const { control, getValues } = useFormContext();
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={getValues(name)}
        render={({ field }) => {
          return (
            <InputComponent
              isButton
              className="d-flex custom-color-input justify-content-end"
              disabled={true}
              size={"lg"}
              {...field}
              value={value ? value : field.value}
            />
          );
        }}
      />
    </>
  );
}
