import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useLocale } from "context/locale";
import qs from "query-string";
import "./LanguageSwicher.css";
// import { lowerObject } from "utils";
import { LANGUAGE_OPTIONS, RESPONSIVE } from "constant";

export default function LanguageSwicher() {
  const { setLocale, locale } = useLocale();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });

  return (
    <>
      <div className="switch-lang" style={isMobile ? { fontSize: 13 } : {}}>
        {LANGUAGE_OPTIONS.map((element, index) => {
          return (
            <>
              <span
                className={`lang-link ${
                  element.value.toLowerCase() === locale && "green"
                }`}
                onClick={() => {
                  setLocale(element.value.toLowerCase());
                  const queryParams = qs.parse(location.search);
                  console.log({ queryParams });
                  delete queryParams.country;
                  const newQueries = {
                    ...queryParams,
                    country: `${element.value.toLowerCase()}_CH`,
                  };
                  history.push({ search: qs.stringify(newQueries) });
                }}
              >{`${element.label}`}</span>
              <span>{`${
                index < LANGUAGE_OPTIONS.length - 1 ? " | " : ""
              }`}</span>
            </>
          );
        })}
      </div>
    </>
  );
}
