import _ from "lodash";

// Validation
export default async function Validate(page, trigger, watch) {
  if (page === 2) {
    let listTrigger = [
      "companyName",
      "companyStreet",
      "companyNo",
      "companyZIP",
      "companyPlace",
      "companyCountry",
      "companyFounded",
      "companyUID",
      "companyEmployees",
      "betreibungen",
      "remarks",
    ];
    const triggerRS = await trigger(listTrigger, { shouldFocus: false });
    if (triggerRS) {
      return true;
    } else {
      return false;
    }
  }

  if (page === 3) {
    const isTeleValid =
      (watch("mobile").length > 4 ||
        watch("private").length > 4 ||
        watch("business").length > 4 ||
        watch("otherPhone").length > 4) &&
      !_.isEqual(watch("personPhone"), "defaultTelephone");
    let listTrigger = [
      "salutation",
      "firstName",
      "lastName",
      "birthday",
      "nationality",
      "street",
      "number",
      "zip",
      "place",
      "country",
      "role",
      "personPhone",
      "email",
      "2ndAuthority",
      "driver",
      "controlHolder",
    ];
    if (watch("2ndAuthority") === "Nein") {
      listTrigger = listTrigger.concat([
        "salutation2",
        "firstName2",
        "lastName2",
        "birthday2",
        "nationality2",
        "street2",
        "number2",
        "zip2",
        "place2",
        "country2",
        "MDRole2",
        "email2",
      ]);
    }
    if (watch("driver") === "Nein") {
      listTrigger = listTrigger.concat([
        "salutationDriver",
        "firstNameDriver",
        "lastNameDriver",
      ]);
    }
    if (["2", "2a", "2b", "2c"].includes(watch("controlHolder"))) {
      listTrigger = listTrigger.concat([
        "salutationKInh1",
        "firstNameKInh1",
        "lastNameKInh1",
        "birthdayKInh1",
        "streetKInh1",
        "numberKInh1",
        "postcodeKInh1",
        "placeKInh1",
        "countryKInh1",
        "furtherPerson",
      ]);
    }
    if (
      watch("furtherPerson") === "Ja" &&
      ["2", "2a", "2b", "2c"].includes(watch("controlHolder"))
    ) {
      listTrigger = listTrigger.concat([
        "salutationKInh2",
        "firstNameKInh2",
        "lastNameKInh2",
        "birthdayKInh2",
        "streetKInh2",
        "numberKInh2",
        "postcodeKInh2",
        "placeKInh2",
        "countryKInh2",
      ]);
    }
    const triggerRS = await trigger(listTrigger, { shouldFocus: false });
    if (triggerRS && isTeleValid) {
      return true;
    } else {
      return false;
    }
  }

  if (page === 4) {
    let listTrigger = [
      "pastTurnover2",
      "pastTurnover1",
      "pastResult2",
      "pastResult1",
      "pastEquity2",
      "pastEquity1",
      // "actualRevenue",
      // "monthlyMean",
      "existingContracts",
      "replaceExitContractChecked",
    ];
    if (watch("replaceExitContractChecked") === "Ja") {
      listTrigger.push("attachablepart");
    }
    const triggerRS = await trigger(listTrigger, { shouldFocus: false });
    if (triggerRS) {
      return true;
    } else {
      return false;
    }
  }

  if (page === 5) {
    let listTrigger = ["checkBox1", "checkBox2", "checkBox3"];
    const triggerRS = await trigger(listTrigger, { shouldFocus: false });
    if (triggerRS) {
      return true;
    } else {
      return false;
    }
  }

  return true;
}
