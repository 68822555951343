import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import { useMediaQuery } from "react-responsive";
import { Container, Row, Col } from "react-bootstrap";
import { getSessionToken, thankYouData } from "../../../utils/api";
import { getLocaleFromURL, getParameterByName } from "../../../utils";
import {
  BUSINESS_STEPPER_OPTIONS,
  PRIVATE_STEPPER_OPTIONS,
  RESPONSIVE,
} from "constant";
import CarImage from "components/CarImage";
import { useLocale } from "../../../context/locale";

export default function ThankYou({}) {
  const [data, setData] = useState({});
  const { locale, setLocale } = useLocale();
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE.MAX_WIDTH_SMALL });
  const isMediumSize = useMediaQuery({
    minWidth: RESPONSIVE.MIN_WIDTH_MEDIUM,
    maxWidth: RESPONSIVE.MAX_WIDTH_MEDIUM,
  });
  // const localeContext = useContext(LocaleContext);
  const { formatMessage } = useIntl();
  const location = useLocation();
  const localeURL = getLocaleFromURL(location, "");

  // Fetch data and scroll to top
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSessionToken();
        const { data } = await thankYouData(`${getParameterByName("txNum")}`);
        setLocale((currentLocale) => {
          return localeURL || data.language || currentLocale;
        });
        setData(data);
        //localeContext.setLocale(data["language"]);
        window.scrollTo(0, 0);
        document
          .getElementsByClassName(
            `${isMobile ? "MuiStepLabel-active" : "active"}`
          )[0]
          .scrollIntoView({ behavior: "smooth", inline: "center" });
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [isMobile, localeURL, setLocale]);

  return (
    <div>
      <Header
        stepperOptions={
          data["isPrivate"]
            ? { currentStep: -1, numberOfStep: 6, ...PRIVATE_STEPPER_OPTIONS }
            : { currentStep: -1, numberOfStep: 6, ...BUSINESS_STEPPER_OPTIONS }
        }
      />
      {isMobile && (
        <CarImage data={data} isMobile={isMobile} isMediumSize={isMediumSize} />
      )}
      <Container
        className={`px-4`}
        style={
          isMobile
            ? { backgroundColor: "white", overflow: "auto", marginTop: 15 }
            : { backgroundColor: "white", height: "calc(100vh - 58px)" }
        }
      >
        <div
          className={`content-label ${isMobile ? "s-14 py-0" : "s-16 py-4"}`}
          style={isMobile ? { height: "50px" } : {}}
        >
          {formatMessage({ id: "forward.text.sehr" })}
          {`${
            String(data["title"]).toLowerCase() === "frau"
              ? ` ${formatMessage({ id: "data.icon.mrs" })}`
              : `${locale === "de" ? "r" : ""} ${formatMessage({
                  id: "data.icon.mr",
                })}`
          } ${data["lastName"]}`}
        </div>
        <Row className={`${isMobile ? "mt-2" : "mt-2 mb-4"}`}>
          <Col>
            <div className={`${isMobile ? "s-14" : "s-16"} pr-4`}>
              <>
                <div className={isMobile ? "mb-2" : "mb-4"}>
                  <FormattedMessage id="thankyou.text.paragraph1" />
                </div>
                <div className={isMobile ? "mb-2" : "mb-4"}>
                  <FormattedMessage id="thankyou.text.paragraph2" />
                </div>
              </>
              <div className={isMobile ? "mb-2" : "mb-4"}>
                <FormattedMessage
                  id="thankyou.text.bestregards"
                  /* defaultMessage="Freundliche Grüsse" */
                />
              </div>
              <div className={isMobile ? "mb-2" : "mb-4"}>
                <FormattedMessage
                  id="thankyou.text.leaseteq"
                  /* defaultMessage="Ihr LeaseTeq Team" */
                />
              </div>
            </div>
          </Col>
          {!isMobile && (
            <CarImage
              data={data}
              isMobile={isMobile}
              isMediumSize={isMediumSize}
            />
          )}
        </Row>
      </Container>
    </div>
  );
}
